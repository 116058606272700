//React, React Router, Formik
import React, {useEffect} from 'react';
import theme from "@helper/theme/theme";

//Redux
import {useSelector} from "react-redux";
import {alpha} from "@material-ui/core/styles";

const useCustomTheme = () => {

    const {colorPalette} = useSelector(state => state.siteDetail);

    useEffect(() => {
        if (colorPalette) {
            const palette = {
                primary: colorPalette.primary,
                secondary: colorPalette.secondary,
                grey: {
                    iron: '#dcdee1',
                    limed_spruce: '#394649',
                    wild_sand: '#f4f4f4',
                    persian_blue: '#3722D3',
                    corduroy: '#656868',
                    dodger_blue: '#00b7ff',
                    cadet_blue: '#abb8c3',
                    concrete: '#f3f3f3'
                },
                success: {
                    background: '#07bc0c',
                    text: '#fff',
                    main: '#07bc0c',
                },
                danger: {
                    text: '#FF4A4A',
                },
                link: {
                    main: '#3722d3',
                },
                text: {
                    // primary: '#FFF',
                },
                landingPage: {
                    primary: '#dc5353',
                    secondary: '#00a2ff',
                    secondary2: '#00a8ff',
                    body: '#F5F7F8',
                    body2: '#f7f8fc',
                    body3: '#36434D',
                    body4: '#f7f9fa',
                    body5: '#173145',
                    font: '#172f44',
                    font2: '#5e6971',
                    font3: '#455f70',
                    font4: '#5d5d5d',
                    font5: '#b0c2cd',
                    font6: '#303030',
                    font7: '#27282B',
                    link: '#2096ef',
                    link2: '#007bff',
                    bg1: '#DADADA'
                }
            };

            theme.palette = palette;

        //
            theme.overrides = {
                MuiCssBaseline: {
                    ...theme.MuiCssBaseline,
                    "@global": {
                        body: {
                            backgroundColor: palette.grey.wild_sand,
                            color: palette.primary.main,
                            fontSize: '15px',
                            lineHeight: '20px',
                            "&.spinner": {
                                overflow: 'hidden',
                            }
                        },
                        a: {
                            color: 'inherit',
                            textDecoration: 'none',
                            "&:hover": {
                                textDecoration: 'underline'
                            },
                            "&:visited": {
                                color: 'initial'
                            }
                        },
                        "WebkitLink": {
                            color: palette.primary.main,
                        }
                    },
                },
                MuiLink: {
                    ...theme.MuiLink,
                    root: {
                        // color: palette.grey.persian_blue,
                    }
                },
                MuiButton: {
                    ...theme.MuiButton,
                    root: {
                        textTransform: "none",
                        color: palette.primary.main,
                        fontSize: '15px',
                        fontWeight: 'bold',
                        lineHeight: '20px',
                        padding: '0 20px',
                        borderRadius: '4px',
                        height: 32,
                    },
                    text: {
                        color: palette.grey.persian_blue,
                        padding: '1px 6px',
                        "&:hover": {
                            backgroundColor: 'unset',
                            textDecoration: 'underline',
                        }
                    },
                    textPrimary: {
                        color: '#FFF',
                        padding: '0 9px',
                        "&:hover": {
                            color: palette.primary.dark,
                            backgroundColor: 'unset',
                        }
                    },
                    textSecondary: {
                        color: '#FFF',
                        padding: '0 9px',
                        "&:hover": {
                            color: palette.secondary.main,
                            backgroundColor: 'unset',
                        }
                    },
                    contained: {
                        boxShadow: 'none',
                        "&:hover": {
                            boxShadow: 'none',
                        }
                    },
                    containedSecondary: {
                        "&:hover": {
                            backgroundColor: palette.secondary.dark,
                        }
                    },
                    containedSizeLarge: {
                        fontSize: '18px',
                        height: '40px',
                        // lineHeight: 38,
                        minWidth: '96px'
                    },
                    sizeLarge: {
                        fontSize: '18px',
                    },
                    outlinedPrimary: {
                        "&:hover": {
                            backgroundColor: palette.primary.main,
                            color: '#FFF',
                        }
                    }
                },
                MuiIconButton: {
                    ...theme.MuiIconButton,
                    root: {
                        "&:hover": {
                            backgroundColor: 'unset'
                        }
                    }
                },
                MuiRadio: {
                    ...theme.MuiRadio,
                    colorPrimary: {
                        "&$checked": {
                            color: palette.grey.persian_blue
                        }
                    }
                },

                MuiFormControl: {
                    ...theme.MuiFormControl,
                    root: {
                        "& .MuiFormLabel-root": {
                            //color: palette.grey.blue_bayoux,
                        },
                        "& .MuiFormHelperText-root": {
                            marginLeft: 0
                        },
                    },
                },
                MuiFormHelperText: {
                    ...theme.MuiFormControl,
                    root: {
                        fontSize: '13px',
                        lineHeight: '20px',
                        color: palette.primary.main,
                    },
                },
                MuiOutlinedInput: {
                    ...theme.MuiOutlinedInput,
                    root: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: `1px solid ${palette.grey.iron}`,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: [`1px solid ${palette.grey.persian_blue}`, '!important']
                        },
                        "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: `1px solid ${palette.grey.iron}`,
                            },
                        },
                    },
                    input: {
                        width: '100%',
                        height: 38,
                        padding: '0 14px',
                        borderRadius: '4px',
                        /*Not Working*/
                        "&::placeholder": {
                            color: palette.primary.main,
                        }
                    }
                },
                MuiSelect: {
                    ...theme.MuiSelect,
                    selectMenu: {
                        whiteSpace: 'unset'
                    },
                },
                MuiBackdrop: {
                    ...theme.MuiBackdrop,
                    root: {
                        backgroundColor: alpha('#000', 0.8),
                        cursor: 'zoom-out',
                    }
                },
                MuiSvgIcon: {
                    ...theme.MuiSvgIcon,
                    root: {
                        fontSize: '24px',
                    }
                }
            }
        //

        }
    }, [colorPalette])

    return  colorPalette && theme;
};

export default useCustomTheme;
