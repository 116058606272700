//React, React Router, Formik
import React from 'react';
import {useRouter} from "next/router";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid} from "@material-ui/core";

//Redux
import {useSelector} from "react-redux";
import CustomImage from "@util/CustomImage/CustomImage";
import {getApiUrl} from "@helper/constants/constant";

//Assets

const useStyles = makeStyles(theme => ({
    authBtn: {
        height: 40,
        fontSize: '14.98px',
        fontWeight: 'bold',
        borderRadius: '4px',
        letterSpacing: '0.2px',
        border: `1px solid ${theme.palette.grey.limed_spruce}`,
        padding: theme.spacing(1.25, 3.75),
        marginBottom: theme.spacing(3),
        backgroundColor: '#FFF',
        "&:hover": {
            backgroundColor: '#FFF'
        },
        "& .MuiButton-label": {
            justifyContent: 'flex-start',
            position: 'relative'
        }
    },
    iconWrapper: {
      position: 'absolute',
        "& span": {
            marginRight: theme.spacing(17.5),
            marginLeft: theme.spacing(-1),
            verticalAlign: 'middle',
            [theme.breakpoints.down('lg')]: {
                marginRight: theme.spacing(9.75),
            }
        }
    },
    name: {
        whiteSpace: 'nowrap',
        color: theme.palette.primary.main,
        position: 'absolute',
        left: 70
    },
    formSeparatorWrapper: {
        marginBottom: theme.spacing(3),
    },
    formSeparator: {
        height: '1px',
        margin: theme.spacing(3, 0, 6),
        borderTop: props => {
            if (props.variant === 'dark') {
                return '1px solid #FFF'
            } else if (props.variant === 'grey') {
                return `1px solid ${theme.palette.primary.main}`
            } else if (props.variant === 'light') {
                return `1px solid ${theme.palette.primary.main}`
            }
        },
        "& p": {
            width: 30,
            margin: theme.spacing(-2.25, 'auto'),
            fontSize: '13px',
            textAlign: 'center',
            fontWeight: 'bold',
            lineHeight: '18px',
            backgroundColor: props => {
                if (props.variant === 'dark') {
                    return theme.palette.primary.main
                } else if (props.variant === 'grey') {
                    return theme.palette.grey.wild_sand
                } else if (props.variant === 'light') {
                    return '#FFF'
                }
            },
        }
    },
}));

const SocialAuthButton = (props) => {

    const classes = useStyles(props);
    const router = useRouter();

    const assetPath = useSelector(state => state.siteDetail.assetPath);

    const dispatchRedirectToSocialProvider = (provider) => {
        const redirectPath = router.query.from ?? '';
        window.location = `${getApiUrl()}/auth/${provider}/redirectToSocialProvider?redirect=${redirectPath}`;
    }

    return (
        <>
            <Grid item container direction="column">
                {/*<Grid item container alignItems="center" className={classes.authBtn} component={Button}
                      onClick={() => dispatchRedirectToSocialProvider('facebook')}>
                    <Grid item className={classes.iconWrapper}>
                        <CustomImage
                            loaded={!!assetPath}
                            src={`${assetPath}facebook-icon.png`}
                            alt="Facebook Icon"
                            width={20}
                            height={20}
                        />

                    </Grid>
                    <Grid item className={classes.name}>
                        Continue with Facebook
                    </Grid>
                </Grid>*/}

                <Grid item container alignItems="center" className={classes.authBtn} component={Button}
                      onClick={() => dispatchRedirectToSocialProvider('google')}
                >
                    <Grid item className={classes.iconWrapper}>
                        <CustomImage
                            loaded={!!assetPath}
                            src={`${assetPath}google-icon.png`}
                            alt="Google Icon"
                            width={20}
                            height={20}
                        />
                    </Grid>
                    <Grid item className={classes.name}>
                        Continue with Google
                    </Grid>
                </Grid>

                {/*Need to uncomment in future*/}
                {/*<Grid item container alignItems="center" className={classes.authBtn} component={Button}
                      onClick={() => dispatchRedirectToSocialProvider('apple')}>
                    <Grid item className={classes.iconWrapper}>
                        <CustomImage
                            loaded={!!assetPath}
                            src={`${assetPath}apple-icon.png`}
                            alt="Apple Icon"
                            width={20}
                            height={20}
                        />
                    </Grid>
                    <Grid item className={classes.name}>
                        Continue with Apple
                    </Grid>
                </Grid>*/}
            </Grid>


            {/*OR*/}
            <Grid item className={classes.formSeparatorWrapper}>
                <div className={classes.formSeparator}>
                    <p>or</p>
                </div>
            </Grid>
        </>
    );
};

export default SocialAuthButton;
