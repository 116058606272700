

import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    drawerMobileOpen: false,
    spinner: false,
    purchaseSpinner: false,
    pendingRequest: false,
    errorMessages: null,
    apiResponse: {
        snackbar: {
            severity: null,
            message: '',
        },
        formValidation: null
    },
    productList: null, //Only id and title
    snackbar2: {
        state: false,
        message: ''
    },
    isDBCDomain: false,
    isZSDomain: false,
}

const slice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggleDrawer (state) {
            state.drawerMobileOpen = !state.drawerMobileOpen;
        },
        toggleSpinner (state, action) {
            state.spinner = action.payload.spinner;
        },
        togglePurchaseSpinner (state, action) {
            state.purchaseSpinner = action.payload.spinner;
        },
        setPendingRequest (state, action) {
            state.pendingRequest = action.payload.pendingRequest
        },
        setErrorMessages (state, action) {
            state.errorMessages = action.payload.messages
        },
        resetErrorMessages (state) {
            state.errorMessages = initialState.errorMessages
        },
        setSuccessApiResponse (state, action) {
            state.apiResponse.snackbar.severity = 'success';
            state.apiResponse.snackbar.message = action.payload.message;
        },
        setErrorApiResponse (state, action) {
            if (action.payload.errors) {
                state.apiResponse.formValidation = action.payload.errors;
            } else if (action.payload.message) {
                state.apiResponse.snackbar.severity = 'error';
                state.apiResponse.snackbar.message = action.payload.message;
            }
        },
        unsetSnackbar (state, action) {
            state.apiResponse.snackbar.severity = null;
            state.apiResponse.snackbar.message = '';
        },
        setProductList (state, action) {
            state.productList = action.payload.product_list
        },
        unsetProductList (state, action) {
            state.productList = initialState.productList
        },
        setSnackbar2 (state, action) {
            state.snackbar2.state = true;
            state.snackbar2.message = action.payload.message;
        },
        unsetSnackbar2 (state) {
            state.snackbar2 = initialState.snackbar2
        },
        setIsDBCDomain (state) {
            state.isZSDomain = false;
            state.isDBCDomain = true;
        },
        setIsZSDomain (state) {
            state.isDBCDomain = false;
            state.isZSDomain = true;
        },
    },
    extraReducers: {
        [HYDRATE] (state, action) {
            state.spinner = action.payload.ui.spinner;
        }
    }
});

export const uiActions = slice.actions;

export default slice.reducer;
