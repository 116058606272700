//React, React Router, Formik
import React, {useEffect} from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import FacebookCircularProgress from "./FacebookCircularProgress/FacebookCircularProgress";
import {Grid, Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        position: 'fixed',
        top: 0,
        zIndex: 2,
        background: '#FFF',
        padding: `0 ${theme.spacing(7.5)}px`
    },
    spinnerWrapper: {
        alignSelf: 'center'
    },
    text1: {
        color: theme.palette.primary.main,
        margin: `${theme.spacing(7.5)}px 0 ${theme.spacing(7.5)}px`,
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: 1.2
    },
    text2: {
        color: theme.palette.grey.limed_spruce,
        marginBottom: theme.spacing(2.5),
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: 1.5,
        maxWidth: '30em',
        margin: 'auto'
    }
}));

const PurchaseSpinner = () => {

    const classes = useStyles();

    useEffect(() => {
        document.body.classList.add('spinner');

        return () => {
            document.body.classList.remove('spinner');
        }
    }, []);

    return (
        <Grid container direction="column" justifyContent="center" className={classes.root}>
            <Grid item className={classes.spinnerWrapper}>
                <FacebookCircularProgress />
            </Grid>
            <Grid item>
                <Typography className={classes.text1} align="center">
                    Processing your purchase for Membership
                </Typography>
            </Grid>
            <Grid item>
                <Typography className={classes.text2} align="center">
                    Please sit tight and do not refresh or click away from this page.
                    This process can take upto 60 seconds.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PurchaseSpinner;
