//React, React Router, Formik
import React, {useEffect, useState} from 'react';
import Link from "next/link";
import NotificationBar from './NotificationBar/NotificationBar';
import {CHECKOUT, ORDER, WELCOME} from "@helper/constants/routes";
import SearchOverlay from "./SearchOverlay/SearchOverlay";
import SearchInput from "./SearchInput/SearchInput";
import BrowseMenu from "./BrowseMenu/BrowseMenu";
import ProfileMenu from "@ui/ProfileMenu/ProfileMenu";
import AuthButton from "./AuthButton/AuthButton";
import AuthModal from "@ui/AuthModal/AuthModal";
import {USER} from "@helper/constants/routes";
import {useRouter} from "next/router";

//Material UI
import {alpha, makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useMediaQuery} from "@material-ui/core";
import {Hidden, IconButton, InputBase} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

//Redux
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "@store/ui/slice";
import Head from "next/head";

//Assets

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(10.75),
    },
    appBar: {
        padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`,
        background: theme.palette.primary.main,
        height: '80px',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            height: '64px',
        }
    },
    toolbar: {
        padding: 0,
        justifyContent: 'space-between'
    },
    iconButtons: {},
    menuBtn: {
        padding: 0,
        color: '#FFF',
    },
    closeIcon: {
        width: 24,
        height: 24,
        color: theme.palette.secondary.main
    },
    searchBtn: {
        padding: `${theme.spacing(1)}px 0 0 ${theme.spacing(4.5)}px `,
        color: '#FFF',
    },
    logoWrapper: {
        //backgroundImage: `url(${logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        [theme.breakpoints.down('md')]: {
            width: '84px',
            height: 37,
            backgroundSize: '72px',
            //flexGrow: 1,
        }
    },
    browseWrapper: {
        padding: theme.spacing(0, 8),
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            padding: 0
        }
    },
    profileMenu: {
        display: 'flex',
        "& .MuiButton-root": {
            "& .MuiButton-label": {
                color: "#FFF",
                fontSize: '15px',
                fontWeight: 'bold',
                "&:hover": {
                    color: theme.palette.secondary.main
                }
            }
        },
    },
    myClassesButton: {
        "&:hover": {
            textDecoration: 'none'
        }
    },
    loginButton: {
        fontSize: '0.9rem',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        borderRadius: '3px',
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing(1.75)}px ${theme.spacing(4.25)}px`,
        },
    }
}));

const Header = (props) => {

    const classes = useStyles();
    const router = useRouter();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('signIn');
    const [searchOverlayModalOpen, setSearchOverlayModalOpen] = useState(false);

    const {isAuthenticated, auth_user} = useSelector(state => state.auth);
    const brandingLogo = useSelector(state => state.siteDetail.brandingLogo);
    const {drawerMobileOpen, isDBCDomain, isZSDomain} = useSelector(state => state.ui);


    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const isCheckoutPage = router.pathname === CHECKOUT.show || router.pathname === ORDER.show;

    const changeModalMode = (mode) => {
        setModalMode(mode);
    }

    const handleAuthModalOpen = (mode) => {
        setAuthModalOpen(true);
        changeModalMode(mode);
    };

    const handleAuthModalClose = () => {
        setAuthModalOpen(false);
    };

    const handleOverlayModalOpen = () => {
        setSearchOverlayModalOpen(true);
    }

    const handleOverlayModalClose = () => {
        setSearchOverlayModalOpen(false);
    }

    const toggleDrawer = () => {
        dispatch(uiActions.toggleDrawer());
    };

    return (
        <>
            {
                !isCheckoutPage &&
                <Hidden mdDown>
                    <NotificationBar/>
                </Hidden>
            }

            {
                !isDBCDomain && !isZSDomain && searchOverlayModalOpen &&
                <SearchOverlay
                    searchOverlayModalOpen={searchOverlayModalOpen}
                    searchOverlayModalCloseHandler={handleOverlayModalClose}
                />
            }

            <div className={classes.root}>
                <AppBar position="static" color="transparent" elevation={0} className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>

                        {/*Menu Button*/}
                        {
                            !isCheckoutPage && !isDBCDomain && !isZSDomain &&
                            <Hidden lgUp>
                                <div className={classes.iconButtons}>
                                    <IconButton className={classes.menuBtn} onClick={toggleDrawer}>
                                        {drawerMobileOpen ? <CloseIcon className={classes.closeIcon}/> : <MenuIcon/>}
                                    </IconButton>
                                    {/*<IconButton className={classes.searchBtn} onClick={handleOverlayModalOpen}>
                                        <SearchIcon/>
                                    </IconButton>*/}
                                </div>
                            </Hidden>
                        }

                        {/*Logo*/}
                        <Link href={!isCheckoutPage ? WELCOME.show : {}}>
                            <a className={classes.logoWrapper}
                               onClick={(event) => isCheckoutPage ? event.preventDefault() : null}
                               style={{
                                   backgroundPosition: isCheckoutPage && matchesDownMd ? 'left center' : 'center center',
                                   backgroundImage: brandingLogo ? `url(${brandingLogo.url.replace(/\\/g, "/")})` : 'unset',
                                   backgroundSize: isDBCDomain ? '46px 50px' : '104px 52px',
                                   width: isDBCDomain ? '46px' : '104px',
                                   height: isDBCDomain ? '50px' : '52px'
                               }}
                            />
                        </Link>

                        {/*Browse Button & Search Input*/}
                        {
                            !isCheckoutPage &&
                            <Hidden mdDown>
                                <div className={classes.browseWrapper}>
                                    {!isDBCDomain && !isZSDomain && <BrowseMenu/>}

                                    <Hidden mdDown>
                                        {!isDBCDomain && !isZSDomain && <SearchInput/>}
                                    </Hidden>

                                </div>
                            </Hidden>
                        }

                        {/*Profile Button & My Books Button*/}
                        {
                            isAuthenticated && auth_user &&
                            <div className={classes.profileMenu}>

                                {
                                    !isCheckoutPage &&
                                    <Hidden mdDown>
                                        <Link href={USER.library}>
                                            <Button className={classes.myClassesButton} component="a">
                                                {`My ${isZSDomain || isDBCDomain ? 'Courses' : 'Books'}`}
                                            </Button>
                                        </Link>
                                    </Hidden>
                                }

                                <ProfileMenu />
                            </div>
                        }

                        {/*Auth Button*/}
                        {
                            !isAuthenticated &&
                            <div>
                                <AuthButton openHandler={handleAuthModalOpen}/>
                            </div>
                        }

                    </Toolbar>
                </AppBar>
            </div>

            {/*Auth Modal*/}
            {
                !isAuthenticated && authModalOpen &&
                <AuthModal
                    open={authModalOpen}
                    closeHandler={handleAuthModalClose}
                    modalMode={modalMode}
                    modalModeHandler={changeModalMode}
                />
            }
        </>
    );
}

export default Header
