import {uiActions} from "./slice";
import axiosInstance from "@helper/axios";
import {DBCDomain, ZSDomain} from "@helper/constants/constant";
import {getDomain} from "@helper/functions";

//const basePath = '/SiteDetails';

let snackbar2Timer = null;

export const getProductList = (text_search) => {
    return dispatch => {
        axiosInstance.get(`products/searchProductList`, {
            params: {
                text_search
            }
        })
            .then(response => {
                dispatch(uiActions.setProductList(response));
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}

export const clearProductList = () => {
    return dispatch => {
        dispatch(uiActions.unsetProductList());
    }
}

export const setSnackbar2 = (message) => {
    return dispatch => {
        dispatch(uiActions.setSnackbar2({message}));
        snackbar2Timer = setTimeout(() => {
            dispatch(uiActions.unsetSnackbar2());
        }, 4000);
    }
}

export const unsetSnackbar2 = () => {
    return dispatch => {
        dispatch(uiActions.unsetSnackbar2());
        if (snackbar2Timer) {
            clearTimeout(snackbar2Timer);
        }
    }
}

export const setWhichDomain = () => {
    return dispatch => {
        if (typeof window !== 'undefined') {
            let domain = getDomain(window.location.host);
            if (domain === DBCDomain) {
                dispatch(uiActions.setIsDBCDomain());
            } else if (domain === ZSDomain) {
                dispatch(uiActions.setIsZSDomain());
            }
        }
    }
}
