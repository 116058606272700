import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";
import {getMostPopularProduct} from "@store/Welcome/actions";

const initialState = {
    categoryWiseProducts: null,
    mostPopularProduct: null,
    authWelcomeData: {
        recommendedProducts: null,
        featuredProducts: null,
        trendingProducts: null,
        resumeChapters: null,
    },
}

const slice = createSlice({
    name: 'welcome',
    initialState,
    reducers: {
        setCategoryWiseProducts(state, action) {
            state.categoryWiseProducts = action.payload.category_wise_products;
        },
        unsetCategoryWiseProducts(state, action) {
            state.categoryWiseProducts = initialState.categoryWiseProducts;
        },
        setAuthWelcomeData(state, action) {
            state.authWelcomeData.recommendedProducts = action.payload.recommended_products;
            state.authWelcomeData.featuredProducts = action.payload.featured_products;
            //state.authWelcomeData.trendingProducts = action.payload.trending_products;
            state.authWelcomeData.resumeChapters = action.payload.resume_chapters;
        },
        updateResumeChapters(state, action) {
            const resumeChaptersClone = JSON.parse(JSON.stringify(state.authWelcomeData.resumeChapters));
            state.authWelcomeData.resumeChapters = resumeChaptersClone.filter(chapter => chapter.id !== action.payload.chapterId);
        },
        toggleBookmark(state, action) {

            if (action.payload.slice === 'welcomeProducts') {
                let authWelcomeDataClone = JSON.parse(JSON.stringify(state.authWelcomeData))

                Object.keys(authWelcomeDataClone).map(function (key, index) {
                    let currentProductCollection = authWelcomeDataClone[key];

                    if (currentProductCollection && currentProductCollection.length) {
                        let targetProductIndex = currentProductCollection.findIndex(product => {
                            return product.product_id === action.payload.productId
                        });

                        if (targetProductIndex !== -1) {
                            currentProductCollection[targetProductIndex].is_bookmarked = action.payload.status;
                            state.authWelcomeData[key] = currentProductCollection;
                        }
                    }
                });
            } else if (action.payload.slice === 'categoryWiseProducts') {

                let categoryWiseProductsClone = JSON.parse(JSON.stringify(state.categoryWiseProducts))

                if (categoryWiseProductsClone && categoryWiseProductsClone.length) {
                    let targetProductIndex = categoryWiseProductsClone.findIndex(product => {
                        return product.product_id === action.payload.productId
                    });

                    if (targetProductIndex !== -1) {
                        categoryWiseProductsClone[targetProductIndex].is_bookmarked = action.payload.status;
                        state.categoryWiseProducts = categoryWiseProductsClone;
                    }
                }
            } else if (action.payload.slice === 'mostPopularProduct') {

                let mostPopularProductClone = JSON.parse(JSON.stringify(state.mostPopularProduct))
                if (mostPopularProductClone) {
                    mostPopularProductClone.is_bookmarked = action.payload.status;
                    state.mostPopularProduct = mostPopularProductClone;
                }
            }
        },
        setMostPopularProduct(state, action) {
            state.mostPopularProduct = action.payload.most_popular_product
        },
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            if (action.payload.welcome.categoryWiseProducts) {
                state.categoryWiseProducts = action.payload.welcome.categoryWiseProducts;
                state.mostPopularProduct = action.payload.welcome.most_popular_product;
            }

            if (action.payload.welcome.authWelcomeData) {
                state.authWelcomeData = action.payload.welcome.authWelcomeData;
            }
        }
    }
});

export const welcomeActions = slice.actions;

export const selectCategoryWiseProducts = state => state.categoryWiseProducts;

export default slice.reducer;
