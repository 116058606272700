import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    showData: {
        teams: null
    },
}

const slice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setShowData(state, action) {
            console.log('action: ', action);
            state.showData.teams = action.payload.teams;
        },
        resetShowData(state, action) {
            state.showData = initialState.showData
        },
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            if (action.payload.team.showData.teams) {
                state.showData.teams = action.payload.team.showData.teams
            }
        }
    }
});

export const teamActions = slice.actions;

export default slice.reducer;
