//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux
import {useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        width: 284,
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    contentWrapper: {
        padding: theme.spacing(7.5),
    },
    welcomeText: {
        marginBottom: theme.spacing(4),
        fontSize: '22px',
        fontWeight: 'bold',
        lineHeight: '28px',
        color: '#FFF',
        "&::selection": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main
        },
    },
    separator: {
        height: 0,
        width: 100,
        margin: theme.spacing(6, 0),
        borderRadius: '8px',
        border: `4px solid ${theme.palette.secondary.main}`
    },
    actionText: {
        fontSize: '18px',
        lineHeight: '22px',
        color: '#FFF',
        "&::selection": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main
        },
    }
}));

const DirectionText = ({modalMode}) => {

    const classes = useStyles();

    const {appMeta} = useSelector(state => state.siteDetail);
    const {isDBCDomain} = useSelector(state => state.ui);

    return (
        <Grid container alignItems="center" className={classes.root}>

            <Grid item className={classes.contentWrapper}>
                <Typography className={classes.welcomeText}>
                    {
                        modalMode === 'signIn' ?
                            <span>Welcome Back to {appMeta?.appName}</span> :
                            <span>Sign Up for Free</span>
                    }
                </Typography>

                <div className={classes.separator}/>

                <Typography className={classes.actionText}>
                    {
                        modalMode === 'signIn' ?
                            <span>Sign in to continue to your account.</span> :
                            (
                                isDBCDomain ?
                                    <span>Grow your Social Media from 0 to 100,000+ followers and transform it into a high-income money printing machine without even quitting your job or studies. 😉</span> :
                                    <span>Explore books in a way never done before with high class chapter wise animations and professional voice over.</span>
                            )

                    }
                </Typography>

            </Grid>

        </Grid>
    );
};

export default DirectionText;
