//React, React Router, Formik
import React, {useEffect} from 'react';
import {Router} from "next/router";
import {useRouter} from "next/router";

//Redux
import {useSelector} from "react-redux";

const useGtmVirtualPageView = () => {

    const {auth_user} = useSelector(state => state.auth);

    const router = useRouter();

    const pushIntoDataLayer = (data) => {
        window.dataLayer?.push({
            event: 'login',
            ...data,
        });
    }

    useEffect(() => {
        if (auth_user?.id) {
            const mainDataLayer = {
                pagePath: router.pathname,
                visitorId: auth_user.id,
            };
            pushIntoDataLayer(mainDataLayer);
        }
    }, [router.asPath, auth_user]);

    /*Router.events.on('routeChangeComplete', () => {
        console.log('authUser11-outside: ', auth_user);
        if (auth_user?.id) {
            console.log('authUser11-inside: ', auth_user);
            const mainDataLayer = {
                pagePath: router.pathname,
                visitorId: auth_user.id,
            };
            pushIntoDataLayer(mainDataLayer);
        }
    });*/

    return null;
};

export default useGtmVirtualPageView;
