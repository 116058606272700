import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    editData: {
        user: null
    },
    myProducts: null
}

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setEditData (state, action) {
            state.editData.user = action.payload.user;
        },
        unsetSocialProvider (state, action) {
            let editUserClone = JSON.parse(JSON.stringify(state.editData.user));
            let socialLinkedAccounts = editUserClone.social_linked_accounts.filter(provider => provider !== action.payload.provider);
            editUserClone.social_linked_accounts = socialLinkedAccounts;
            state.editData.user = editUserClone;
        },
        setMyProducts (state, action) {
            state.myProducts = action.payload.my_products;
        },
        unsetMyProducts (state, action) {
            state.myProducts = initialState.myProducts;
        },
        toggleMarkAsComplete (state, action) {
            let products = JSON.parse(JSON.stringify(state.myProducts))

            if (products && products.length) {
                let targetProductIndex = products.findIndex(product => {
                    return product.product_id === action.payload.productId
                });

                if (targetProductIndex !== -1) {
                    products[targetProductIndex].is_completed = action.payload.status;
                    state.myProducts = products;
                }
            }
        },
        removeBookmark(state, action) {
            let products = JSON.parse(JSON.stringify(state.myProducts))

            if (products && products.length) {
                let targetProductIndex = products.findIndex(product => {
                    return product.product_id === action.payload.productId
                });

                if (targetProductIndex !== -1) {
                    products.splice(targetProductIndex, 1);
                    state.myProducts = products;
                }
            }
        },
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            if (action.payload.user.editData.user) {
                state.editData.user = action.payload.user.editData.user;
            }
        }
    }
});

export const userActions = slice.actions;

export default slice.reducer;
