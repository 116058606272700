//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Hidden} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useMediaQuery} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    signInBtnWrapper: {
        //padding: theme.spacing(0, 2.25),
        position: 'relative',
        "& .MuiButton-root": {
            "&:hover": {
                textDecoration: 'none',
            }
        }
    }
}));

const AuthButton = ({openHandler}) => {

    const classes = useStyles();
    const theme = useTheme();

    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container alignItems="center">

            {/*Sign in Button*/}
            <Grid item className={classes.signInBtnWrapper}>
                <Button
                    variant={matchesDownMd ? "contained" : "text"}
                    color="secondary"
                    onClick={() => openHandler('signIn')}
                >
                    Sign In
                </Button>
            </Grid>

            {/*Sign up Button*/}
            <Hidden mdDown>
                <Grid item className={classes.signupBtnWrapper}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => openHandler('signUp')}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Hidden>
        </Grid>
    );
};

export default AuthButton;
