import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {createWrapper} from "next-redux-wrapper";

import uiReducer from '@store/ui/slice';
import authReducer from '@store/Auth/slice';
import userReducer from '@store/Operator/User/slice';
import orderReducer from '@store/Order/slice';
import checkoutReducer from '@store/Checkout/slice';
import productReducer from '@store/Product/slice';
import couponReducer from '@store/Coupon/slice';
import SiteDetailReducer from '@store/SiteDetail/slice'
import WelcomeReducer from '@store/Welcome/slice'
import ChapterReducer from '@store/Chapter/slice'
import BookmarkReducer from '@store/Bookmark/slice'
import HistoryReducer from '@store/History/slice'
import ContactUsReducer from '@store/ContactUs/slice'
import TermsPrivacyAndHelp from '@store/TermsPrivacyAndHelp/slice'
import Affiliate from '@store/Affiliate/slice';
import AffiliateVisit from '@store/AffiliateVisit/slice';
import Team from '@store/Team/slice';

const combineReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    user: userReducer,
    order: orderReducer,
    checkout: checkoutReducer,
    product: productReducer,
    coupon: couponReducer,
    siteDetail: SiteDetailReducer,
    welcome: WelcomeReducer,
    chapter: ChapterReducer,
    bookmark: BookmarkReducer,
    history: HistoryReducer,
    contactUs: ContactUsReducer,
    termsPrivacyAndHelp: TermsPrivacyAndHelp,
    affiliate: Affiliate,
    affiliateVisit: AffiliateVisit,
    team: Team,
})

export const makeStore = () => {
    return configureStore({
        reducer: combineReducer,
        //devTools: typeof window !== 'undefined' && (window.location.host === 'localhost' || window.location.host === '127.0.0.1')
        devTools: true
    });
}
//const store = ;

export const wrapper = createWrapper(makeStore);

//export default makeStore;
