import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    pay: null,
    createData: {
        sixMonthsPrice: null,
        yearlyPrice: null,
        lifetimePrice: null,
        currency: null,
    },
    finalPrice: 0,
    createdDataLoaded: false
}

const slice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setCreateData(state, action) {
            state.createData.sixMonthsPrice = action.payload.SIX_MONTHS_PRICE
            state.createData.yearlyPrice = action.payload.YEARLY_PRICE
            state.createData.lifetimePrice = action.payload.LIFETIME_PRICE
            state.createData.currency = action.payload.currency
        },
        setFinalPrice(state, action) {
            // console.log('Slice Final Price: ', action.payload.finalPrice);
            state.finalPrice = action.payload.finalPrice;
        },
        resetFinalPrice(state, action) {
            state.finalPrice = initialState.finalPrice;
        },
        setCreateDataLoaded(state) {
            state.createdDataLoaded = true;
        }
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            if (action.payload.checkout.createData) {
                state.createData = action.payload.checkout.createData;
                state.createdDataLoaded = action.payload.checkout.createdDataLoaded;
            }
        }
    }
});

export const checkoutActions = slice.actions;

export default slice.reducer;
