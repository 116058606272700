import {siteDetailActions} from "@store/SiteDetail/slice";
import axiosInstance from "@helper/axios";
import {welcomeActions} from "../Welcome/slice";

const basePath = '/SiteDetails';

export const getSiteData = () => {

    return dispatch => {

        let cachedSiteData = localStorage.getItem('siteData');
        if (cachedSiteData) {
            dispatch(siteDetailActions.setSiteData(JSON.parse(cachedSiteData)));
        }

        axiosInstance.get(`${basePath}/siteData`)
            .then(response => {
                if (JSON.stringify(response) !== cachedSiteData) {
                    console.log('DIDNT CALLED');
                    dispatch(siteDetailActions.setSiteData(response));
                }
                localStorage.setItem('siteData', JSON.stringify(response));
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}
