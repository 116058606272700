//React, React Router, Formik
import React, {useState, useEffect} from 'react';
import {Router, useRouter} from "next/router";
import {authRoutes, protectedRoutes} from "@helper/constants/routes";
import nookies, {parseCookies} from 'nookies'
import {WELCOME, AUTH} from "@helper/constants/routes";

//Redux
import {useDispatch, useSelector} from "react-redux";
import {getSiteData} from "@store/SiteDetail/actions";
import {getAuthUser} from "@store/Auth/actions";
import axiosInstance from "@helper/axios";
import {authActions} from "@store/Auth/slice";
import useGtmVirtualPageView from "@util/hooks/useGtmVirtualPageView/useGtmVirtualPageView";

const useAuth = () => {

    const dispatch = useDispatch();
    const router = useRouter();

    const {authLoading, isAuthenticated} = useSelector(state => state.auth);

    let accessToken = '';
    if (typeof window !== 'undefined') {
        const cookies = parseCookies();
        accessToken = cookies.access_token;

        if (accessToken) {
            axiosInstance.defaults.headers.common['Authorization'] = accessToken;
            dispatch(authActions.setAuthenticated());
        } else {
            dispatch(authActions.setUnAuthenticated());
        }
    }

    useGtmVirtualPageView();

    useEffect(() => {
        //setAccessToken(cookies.access_token);
        dispatch(getSiteData());
        if (accessToken) {
            dispatch(getAuthUser());
        }
    }, [])

    useEffect(() => {

        if (authRoutes.includes(router.pathname)) {
            if (!authLoading && isAuthenticated) {
                if (router?.query?.from) {
                    router.push(router.query.from);
                } else {
                    router.push(WELCOME.show);
                }
            }
        } else if (protectedRoutes.includes(router.pathname)) {
            if (!authLoading && !isAuthenticated) {
                router.push({
                    pathname: AUTH.login,
                    query: { from: router.pathname },
                });
            }
        }
    }, [accessToken, authLoading, isAuthenticated, router.pathname])

    return null;
};

export default useAuth;
