//React, React Router, Formik
import React, {useEffect} from 'react';
import ErrorText from "@ui/ErrorText/ErrorText";
import {useFormik} from "formik";
import {useRouter} from "next/router";
import Link from "next/link";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Button, FormControl, Grid, OutlinedInput} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

//Redux
import {useDispatch, useSelector} from "react-redux";
import {login} from "@store/Auth/actions";

//Assets

const useStyles = makeStyles(theme => ({
    fieldRow: {
        marginBottom: theme.spacing(3),
        width: '100%',
    },
    formControl: {
        width: '48%',
    },
    formHelperText: {
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            textAlign: 'initial',
        }
    },
    inputRoot: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.grey.iron}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: [`1px solid ${theme.palette.grey.persian_blue}`, '!important']
        },
        "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${theme.palette.grey.iron}`,
            },
        },
    },
    inputInput: {
        width: '100%',
        height: 38,
        padding: theme.spacing(0, 3.5),
        borderRadius: '4px',
        /*Not Working*/
        "&::placeholder": {
            color: theme.palette.primary.main,
        }
    },
    signInButton: {
        marginTop: theme.spacing(6),
    },
    pendingSignInButton: {
        marginTop: theme.spacing(6),
        backgroundColor: theme.palette.grey.iron,
        borderColor: theme.palette.grey.iron,
    },
    spinnerContainer: {
        textAlign: 'center',
        whiteSpace: 'noWrap',
        color: theme.palette.grey.limed_spruce,
        cursor: 'default',
        display: 'flex'
    }
}));

const SignInForm = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();

    const errorApiResponse = useSelector(state => state.ui.apiResponse.formValidation);
    const {pendingRequest, isDBCDomain} = useSelector(state => state.ui);
    const {defaultProduct} = useSelector(state => state.siteDetail);

    //Setting form validation errors
    useEffect(() => {
        if (errorApiResponse) {
            Object.keys(errorApiResponse).map(function (key) {
                formik.setFieldError(key, errorApiResponse[key][0]);
            })
        }
    }, [errorApiResponse]);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        onSubmit: values => {
            dispatch(login(values, router, isDBCDomain, defaultProduct?.slug));
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Grid container>

                <ErrorText
                    show={formik.touched.email && Boolean(formik.errors.email)}
                    message={formik.errors.email}
                />

                <Grid item className={classes.fieldRow}>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            labelWidth={0}
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{
                                placeholder: 'Email'
                            }}
                        />
                    </FormControl>
                </Grid>

                <ErrorText
                    show={formik.touched.password && Boolean(formik.errors.password)}
                    message={formik.errors.password}
                />

                <Grid item className={classes.fieldRow}>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            labelWidth={0}
                            type="password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{
                                placeholder: 'Password'
                            }}
                        />
                        {/*<FormHelperText className={classes.formHelperText}>
                            Password must be at least 4 characters long.
                        </FormHelperText>*/}
                    </FormControl>
                </Grid>

                <Grid item style={{width: '100%'}}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        type="submit"
                        className={pendingRequest ? classes.pendingSignInButton : classes.signInButton}
                        endIcon={pendingRequest ? <CircularProgress size={18}/> : undefined}
                    >
                        {!pendingRequest && 'Sign In'}
                    </Button>
                </Grid>

            </Grid>
        </form>
    );
};

export default SignInForm;
