//React, React Router, Formik
import React, { useState, useEffect } from "react";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {uiActions} from "@store/ui/slice";

//Assets

const useValidationMessages = (getValidationMessages, getValidationObject) => {

    const dispatch = useDispatch();

    const errorMessages = useSelector((state) => state.ui.errorMessages);

    useEffect(() => {
        dispatch(getValidationMessages());
    }, []);

    //Unmount Component
    useEffect(() => {
        return () => {
            dispatch(uiActions.resetErrorMessages());
        }
    }, []);

    return getValidationObject(errorMessages);

    //return errorMessages;
};

export default useValidationMessages;
