//React, React Router, Formik
import React from 'react';
import {useRouter} from "next/router";
import Link from "next/link";
import {USER, AFFILIATE, PRODUCT} from "@helper/constants/routes";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Popover from '@material-ui/core/Popover';
import Typography from "@material-ui/core/Typography";
import {Divider, Grid, List, ListItem} from "@material-ui/core";

//Redux
import {logout} from "@store/Auth/actions";
import {useDispatch, useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    profileButton: {
        width: '32px',
        height: '32px',
        cursor: 'pointer',
        [theme.breakpoints.up('lg')]: {
            marginLeft: theme.spacing(4.5),
        }
    },
    popover: {
        ...theme.typography.lmsPopoverMenu1,
    },
    popoverPaper: {
        minWidth: 200,
        marginTop: theme.spacing(3)
    },
    profileSnippet: {
        paddingBottom: theme.spacing(4)
    },
    profileImage: {
        width: 72,
        height: 72,
        marginBottom: theme.spacing(2.5)
    },
    profileName: {
        fontWeight: 'bold',
        fontSize: '15px',
        marginBottom: theme.spacing(2.5)
    },
    editProfileBtn: {
        ...theme.typography.lmsButton1
    },
    divider: {
        backgroundColor: 'unset',
        borderBottom: `1px solid ${theme.palette.grey.wild_sand}`,
    },
    profileLinks: {
        "& .MuiListItem-root": {
            //width: '170px',
            width: '100%',
            fontSize: '15px',
            paddingLeft: theme.spacing(4),
            cursor: 'pointer',
            color: [theme.palette.primary.main, '!important'],
            "&:hover": {
                color: theme.palette.grey.limed_spruce,
                backgroundColor: theme.palette.grey.iron,
                borderRadius: '4px',
                textDecoration: 'none',
            }
        }
    }
}));

const ProfileMenu = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const {auth_user} = useSelector(state => state.auth);
    const {isZSDomain, isDBCDomain} = useSelector(state => state.ui);
    //const {defaultProduct} = useSelector(state => state.siteDetail);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        dispatch(logout(router));
    }

    const open = Boolean(anchorEl);

    const handleEditProfile = (event) => {
        handleClose();
        if (event.ctrlKey) {
            const win = window.open(`${USER.edit}?tab=profile`, "_blank");
            win.focus();
        } else {
            router.push({
                pathname: USER.edit,
                query: {tab: 'profile'}
            });
        }
    }

    return (
        auth_user &&
        <>
            <Avatar
                className={classes.profileButton}
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={handleClick}
                alt={`${auth_user.first_name} ${auth_user?.last_name ?? ''}`}
                src={auth_user.profile_pic}
            />
            <Popover
                className={classes.popover}
                id="profile-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                classes={{paper: classes.popoverPaper}}
            >

                {/*Popover Content*/}
                <Grid container direction="column">

                    {/*Profile Snippet*/}
                    <Grid item container direction="column" alignItems="center" className={classes.profileSnippet}>
                        <Grid item>
                            <Avatar
                                className={classes.profileImage}
                                aria-controls="profile-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                alt={`${auth_user.first_name} ${auth_user.last_name}`}
                                src={auth_user.profile_pic}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.profileName}>
                                {`${auth_user.first_name} ${auth_user?.last_name ?? ''}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.editProfileBtn}
                                variant="contained"
                                color="secondary"
                                onClick={handleEditProfile}
                            >
                                Edit Profile
                            </Button>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider}/>

                    {/*Profile Links*/}
                    <Grid item>
                        <List className={classes.profileLinks}>

                            <Link href={USER.library}>
                                <ListItem onClick={handleClose}>
                                    {`My ${isZSDomain || isDBCDomain ? 'Courses' : 'Books'}`}
                                </ListItem>
                            </Link>

                            <Link href={{
                                pathname: USER.edit,
                                query: {tab: 'order'}
                            }}>
                                <ListItem onClick={handleClose}>
                                    Orders
                                </ListItem>
                            </Link>

                            {
                                auth_user.is_affiliated_user &&
                                <Link href={AFFILIATE.dashboard}>
                                    <ListItem onClick={handleClose}>
                                        Affiliate Area
                                    </ListItem>
                                </Link>
                            }
                            <ListItem onClick={handleLogout}>Sign Out</ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Popover>
        </>
    );
};

export default ProfileMenu;
