import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    createData: {
        user: null,
        subjects: null,
        captchaKey: ''
    }
}

const slice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {
        setCreateData(state, action) {
            state.createData.user = action.payload.user
            state.createData.subjects = action.payload.subjects
            state.createData.captchaKey = action.payload.captcha_key
        }
    },
    extraReducers: {
        [HYDRATE](state, action) {
            if (action.payload.contactUs.createData.user) {
                state.createData = action.payload.contactUs.createData;
            }
        }
    }
});

export const contactUsActions = slice.actions;

export default slice.reducer;
