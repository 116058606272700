import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    showData: {
        terms: '',
        privacy: '',
        help: '',
        about: '',
        careers: '',
        affiliates: '',
        refund: '',
        team: ''
    }
}

const slice = createSlice({
    name: 'termsPrivacyAndHelp',
    initialState,
    reducers: {
        setTerms(state, action) {
            state.showData.terms = action.payload.terms;
        },
        setPrivacy(state, action) {
            state.showData.privacy = action.payload.privacy;
        },
        setHelp(state, action) {
            state.showData.help = action.payload.help;
        },
        setAbout(state, action) {
            state.showData.about = action.payload.about;
        },
        setCareers(state, action) {
            state.showData.careers = action.payload.careers;
        },
        setAffiliates(state, action) {
            state.showData.affiliates = action.payload.affiliates;
        },
        setRefund(state, action) {
            state.showData.refund = action.payload.refund;
        },
        resetShowData(state) {
            state.showData = initialState.showData;
        }
    },
    extraReducers: {
        [HYDRATE] (state, action) {
            state.showData = action.payload.termsPrivacyAndHelp.showData;
        }
    }
});

export const termsPrivacyAndHelpActions = slice.actions;

export default slice.reducer;
