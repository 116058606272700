import {welcomeActions} from "./slice";
import {uiActions} from "@store/ui/slice";
import axiosInstance from "@helper/axios";
import {store as storeBookmark, destroy as deleteBookmark} from "@store/Bookmark/actions";

const basePath = '/welcome';

//Todo: To be deleted because calling from SSR
export const getCategoryWiseProducts = (categorySlug) => {

    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.get(`${basePath}/categoryWiseProducts`, {
            params: {
                conversion: '1280x720',
                category_slug: categorySlug
            }
        })
            .then(response => {
                dispatch(welcomeActions.setCategoryWiseProducts(response));
                dispatch(uiActions.toggleSpinner({spinner: false}));

            })
            .catch(([error, status]) => {
                console.log(error.response);
                dispatch(uiActions.toggleSpinner({spinner: false}));

            })
    }
}

export const unsetCategoryWiseProducts = () => {
    return dispatch => {
        dispatch(welcomeActions.unsetCategoryWiseProducts());
    }
}

//Todo: To be deleted because calling from SSR
export const getAuthWelcomeData = () => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.get(`${basePath}/authWelcomeData`, {
            params: {
                conversion: '1280x720'
            }
        })
            .then(response => {
                dispatch(welcomeActions.setAuthWelcomeData(response));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log(error.response);
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const getMostPopularProduct = () => {
    return dispatch => {
        axiosInstance.get(`${basePath}/mostPopularProduct`, {
            params: {
                conversion: '1280x720'
            }
        })
            .then(response => {
                dispatch(welcomeActions.setMostPopularProduct(response));
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}

export const markChapterAsComplete = (chapterId) => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`userChapters/${chapterId}/markChapterAsComplete`)
            .then(response => {
                dispatch(welcomeActions.updateResumeChapters({chapterId}));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log(error.response);
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const addBookmark = (productId, slice) => {
    return dispatch => {

        dispatch(welcomeActions.toggleBookmark({productId, status: true, slice}));
        dispatch(storeBookmark(productId));
    }
}

export const removeBookmark = (productId, slice) => {
    return dispatch => {

        dispatch(welcomeActions.toggleBookmark({productId, status: false, slice}));
        dispatch(deleteBookmark(productId));
    }
}
