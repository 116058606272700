import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';

//This const is being used in server side as well
//therefore "window" cannot be used for retrieving host
export const DBCDomain = 'dbc';
export const ZSDomain = 'zeeshanshaikh';

export const getApiUrl = (context = null) => {

    let hostname = '';
    let protocol = '';
    let port = ''

    if (typeof window !== 'undefined') {
        hostname = window.location.hostname;
    } else {
        if (context) {
            const {headers} = context.req;
            hostname = headers.host.split(':')[0]
        }
    }

    if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') {
        protocol = 'http://';
        port = ':2083'
    } else {
        protocol = 'https://';
        port = '/portzilla';
    }
    //return `${protocol}${hostname}${port}/api`;
    return `${protocol}${hostname}${port}`;
}

export const THUMBNAIL_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/bmp',
    'image/webp'
];

export const VIDEO_FORMATS = [
    'video/mp4',
    'video/avi',
    'video/mpeg',
    'video/3gp',
];

export const CONTENT_TYPES = [
    {
        id: 'NONE',
        name: 'none',
        icon: <DescriptionOutlinedIcon/>
    },
    {
        id: 'AUDIO',
        name: 'audio',
        icon: <AudiotrackOutlinedIcon/>
    },
    {
        id: 'VIDEO',
        name: 'video',
        icon: <VideocamOutlinedIcon/>
    },
    {
        id: 'QUIZ',
        name: 'quiz',
        icon: <FormatListBulletedOutlinedIcon/>
    },
]

export const SOURCE_TYPES = [
    {
        id: 'UPLOAD',
        name: 'upload',
    },
    {
        id: 'EMBED',
        name: 'embed',
    },
    {
        id: 'LINK',
        name: 'link',
    },
];

export const CONTENT_STATUS = [
    {
        id: 'DRAFT',
        name: 'Draft'
    },
    {
        id: 'PUBLISHED',
        name: 'Published'
    },
];

export const PAYMENT_STATUS = [
    {
        id: 'PENDING',
        name: 'Pending'
    },
    {
        id: 'CANCELLED',
        name: 'Cancelled'
    },
    {
        id: 'CONFIRMED',
        name: 'Confirmed'
    },
    {
        id: 'REFUNDED',
        name: 'Refunded'
    },
    {
        id: 'FAILED',
        name: 'Failed'
    },
    {
        id: 'MANUAL',
        name: 'Manual'
    },
];

export const CONTACT_US_SUBJECTS = [
    {
        id: 'PRE_SALE_QUESTION',
        name: 'Pre Sale Question'
    },
    {
        id: 'PAYMENT_ISSUE',
        name: 'Payment Issue'
    },
    {
        id: 'REFUND',
        name: 'Refund'
    },
    {
        'id': 'VIDEO_ISSUE',
        'name': 'Video Issue',
    },
    {
        'id': 'CONTENT_REQUEST',
        'name': 'Content Request',
    },
    {
        'id': 'PARTNERSHIP_AFFILIATE',
        'name': 'Partnership Affiliate',
    },
];
