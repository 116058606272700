export const objectToFormData = (objectData) => {
    let formData = new FormData();

    for (let key in objectData) {

        if (Array.isArray(objectData[key])) {

            objectData[key].map((data, index) => {
                formData.append(key + '[' + index + ']', data);
            });

        } else {
            formData.append(key, objectData[key]);
        }
    }
    return formData;
}

//Collection is an array of objects
export const getCollectionColumn = (collection, column) => {
    return collection.map(item => {
        return item[column];
    })
}

export const getPaginationRange = (currentPage, perPageCount, totalCount) => {

    let startRange = null;
    let endRange = null;

    if (currentPage && perPageCount) {
        let tempEndRange = currentPage * perPageCount;
        endRange = tempEndRange > totalCount ? totalCount : tempEndRange;
        startRange = tempEndRange - perPageCount + 1;
    }
    return [startRange, endRange];
}

//Pass parameter into routes
export const passRouteParams = (path, params, queryParams = null) => {

    Object.keys(params).map(param => {
        //path = path.replace(`:${param}`, params[param]);
        path = path.replace(`[${param}]`, params[param]);
    });

    if (queryParams) {
        path += queryParams;
    }

    return path;
}

//Create Cookie
export const createCookie = (c_name, c_value, days) => {

    //Get the cookie with name in json
    let cookie = getCookie('coupons');

    let cookieArr = [];

    //If cookie exists then checking if coupon to be stored
    //already exists
    if (cookie) {

        cookieArr = JSON.parse(cookie);
        let coupon = cookieArr.filter(coupon => coupon.name === c_value.name);

        //If yes then do nothing
        if (coupon.length) {
            return;
        }
    }

    //Evaluating expiry duration for cookie
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }

    //Pushing the given coupon in array
    cookieArr.push(c_value);
    let newCookie = JSON.stringify(cookieArr);

    //Saving the cookie
    document.cookie = c_name + "=" + newCookie + expires + "; path=/";

    return true;
}

//Get Cookie
export const getCookie = (c_name) => {
    if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            let c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}

//Remove a coupon from cookie
export const unsetCoupon = (couponName, days) => {

    let cookie = getCookie('coupons');

    //Evaluating expiry duration for cookie
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }

    if (cookie) {
        let cookieArr = JSON.parse(cookie);
        let coupons = cookieArr.filter(coupon => coupon.name !== couponName);
        let newCookie = JSON.stringify(coupons);
        document.cookie = "coupons=" + newCookie + expires + "; path=/";
    }

    return true;
}

export const deleteCookie = (c_name) => {
    document.cookie = `${c_name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

//Get coupon discount
export const getCouponDiscount = (discountType, discount, basePrice = null) => {
    if (discountType === 'FLAT') {
        return discount;
    } else if (discountType === 'PERCENTAGE') {
        return discount / 100 * basePrice;
    }
}

//Load the script
export const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

//Format: 1h 10 min
export const secondsToHoursMinutes = (seconds) => {

    let hours = Math.floor(seconds / 3600);
    let rest_seconds = seconds % 3600;

    let duration = '';
    if (hours) {
        duration += hours+'h ';
    }

    let minutes = Math.floor(rest_seconds / 60);

    if (minutes) {
        duration += minutes+'m';
    }

    return duration;
}

//Format 2:10:30
export const formatDuration = (seconds) => {

    let minutes = Math.floor(seconds / 60);

    let rest_seconds = (seconds % 60).toString().padStart(2, 0);
    //let rest_seconds = str_pad((seconds % 60), 2, STR_PAD_LEFT);

    let hours = Math.floor(minutes / 60);

    let rest_minutes = (minutes % 60).toString().padStart(2, 0);

    let duration = '';

    if (hours) {
        duration += hours+':'+rest_minutes+':'+rest_seconds;
    } else {
        duration += rest_minutes+':'+rest_seconds;
    }

    return duration;
}

//Extract youtube embed ID from URL
export const extractYoutubeEmbedId = (url) => {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

    let match = url.match(regExp);
    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return false;
    }
}

//Get membership plan text
export const getMembershipPlanText = (purchaseType) => {

    let firstText = '';
    let secondText = '';

    if (purchaseType) {
        switch (purchaseType) {
            case 'ONE_MONTH':
                firstText =  '1-month Membership';
                secondText =  'month';
                break;
            case 'THREE_MONTHS':
                firstText =  '3-months Membership';
                secondText =  '3 months';
                break;
            case 'SIX_MONTHS':
                firstText =  '6-months Membership';
                secondText =  '6 months';
                break;
            case 'YEARLY':
                firstText =  '1-year Membership';
                secondText =  'year';
                break;
            case 'LIFETIME':
                firstText =  '*Lifetime Membership';
                secondText =  'lifetime';
                break;
        }
    }

    return [firstText, secondText];
}

export const getNextChapter = (product, activeChapter) => {
    let nextChapter = null;
    product.sections.some(section => {
        const currentIndex = section.chapters.findIndex((chapter) => chapter.id === activeChapter.id);
        const nextIndex = (currentIndex + 1) % section.chapters.length;
        if (nextIndex !== 0 && nextIndex !== currentIndex) {
            nextChapter = section.chapters[nextIndex];
            return true;
        }
    });
    return nextChapter;
}

export const getDomain = (url) => {
    let urlParts = [];

    //If the url does not have protocol then
    //"new URL()" will throw error
    if (url.startsWith('http')) {
        urlParts = new URL(url).hostname.split('.');
    } else {
        urlParts = url.split('.');
    }

    //If url is subdomain then urlParts = ['frontend', 'dbc', 'academy']
    //If url is domain then urlParts = ['dbc', 'academy']
    if (urlParts.length === 2) {
        return urlParts[0];
    } else {
        return urlParts[1];
    }
}
