//React, React Router, Formik
import React, {useEffect} from 'react';
import {useRouter} from "next/router";
import {getCookie} from "@helper/functions";

//Material UI

//Redux
import {useDispatch} from "react-redux";
import {store} from "@store/AffiliateVisit/actions";
import {affiliateVisitActions} from "@store/AffiliateVisit/slice";

const useAffiliate = () => {

    const dispatch = useDispatch();
    const router = useRouter();

    useEffect(() => {

        //Check if url has affiliate_tag
        //const queryParams = new URLSearchParams(location.search);
        //let affiliateTag = queryParams.get('affiliate');

        if (typeof window !== 'undefined') {
            const {affiliate: affiliateTag} = router.query;
            //If yes then create an entry in "affiliate_visits" table
            if (affiliateTag) {
                let currentUrlWithoutDomain = window.location.href.replace(window.location.origin, '');
                let data = {
                    tag: affiliateTag,
                    url: currentUrlWithoutDomain,
                    referrer: document.referrer
                }
                dispatch(store(data));

            } else {
                //If no then check if "affiliateTag" cookie already exists
                let cookie = getCookie('affiliateTag');

                //If yes then set it to redux state
                if (cookie) {
                    dispatch(affiliateVisitActions.setCookieState({cookie: JSON.parse(cookie)}))
                }
            }
        }
    }, [typeof window])



    return null;
};

export default useAffiliate;
