//React, React Router, Formik
import React from 'react';
import {useRouter} from "next/router";
import {PRODUCT} from "@helper/constants/routes";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MenuItem from "@material-ui/core/MenuItem";

//Redux
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "@store/ui/slice";

//Assets

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100vw',
        marginTop: theme.spacing(16)
    },
    modal: {
        top: [theme.spacing(16), '!important'],
    },
    list: {
        padding: theme.spacing(4, 4, 0)
    },
    category: {
        minHeight: 48,
        color: theme.palette.primary.main,
        fontWeight: 700,
        borderBottom: `1px solid ${theme.palette.grey.iron}`,
        padding: 0,
        "&:hover": {
            backgroundColor: 'unset'
        }
    }
}));

const Sidebar = () => {

    const classes = useStyles();
    const router = useRouter();
    const dispatch = useDispatch();

    const drawerMobileOpen = useSelector(state => state.ui.drawerMobileOpen);
    const categories = useSelector(state => state.siteDetail.categories);

    const handleCategoryClick = (categorySlug) => {
        dispatch(uiActions.toggleDrawer());
        router.push(`${PRODUCT.show}${categorySlug ? `?category=${categorySlug}` : ''}`);
    };

    return (
        <div>
            <Drawer
                anchor="left"
                open={drawerMobileOpen}
                ModalProps={{hideBackdrop: true}}
                classes={{
                    paper: classes.paper,
                    modal: classes.modal
                }}
            >
                <List className={classes.list}>
                    <MenuItem
                        className={classes.category}
                        value='all_video_books'
                        onClick={(event) => handleCategoryClick('')}
                    >
                        All VideoBooks
                    </MenuItem>
                    {
                        categories && categories.map(category => (
                            <MenuItem
                                key={category.id}
                                className={classes.category}
                                value={category.id}
                                onClick={(event) => handleCategoryClick(category.id)}
                            >
                                {category.name}
                            </MenuItem>
                        ))
                    }
                </List>
            </Drawer>
        </div>
    );
};

export default Sidebar;
