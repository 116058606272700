//Create Cookie
import {deleteCookie, getCookie} from "./functions";

export const createAffiliateCookie = (c_name, c_value, days) => {

    //Get the cookie with name in json
    let cookie = getCookie(c_name);

    //If cookie exists then delete the cookie
    if (cookie) {
        deleteCookie(c_name);
    }

    //Evaluating expiry duration for cookie
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }

    let newCookie = JSON.stringify(c_value);
    //Saving the cookie
    document.cookie = c_name + "=" + newCookie + expires + "; path=/";

    return true;
}
