//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: theme.palette.grey.iron,
    },
    top: {
        color: theme.palette.grey.persian_blue,
        animationDuration: '1000ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));

const FacebookCircularProgress = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={100}
                thickness={2.5}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={100}
                thickness={2.5}
                {...props}
            />
        </div>
    );
};

export default FacebookCircularProgress;
