const palette = {
    primary: {
        main: '#002333',
        light: '#e6f4fe',
        // text: '#FFF',
    },
    secondary: {
        darker: '#00b25c',
        dark: '#00e677',
        main: '#00FF84',
    },
    grey: {
        iron: '#dcdee1',
        limed_spruce: '#394649',
        wild_sand: '#f4f4f4',
        persian_blue: '#3722D3',
        corduroy: '#656868',
        dodger_blue: '#00b7ff',
        cadet_blue: '#abb8c3',
        concrete: '#f3f3f3'
    },
    success: {
        background: '#07bc0c',
        text: '#fff',
        main: '#07bc0c',
    },
    danger: {
        text: '#FF4A4A',
    },
    link: {
        main: '#3722d3',
    },
    text: {
        // primary: '#FFF',
    },
    landingPage: {
        primary: '#dc5353',
        secondary: '#00a2ff',
        secondary2: '#00a8ff',
        body: '#F5F7F8',
        body2: '#f7f8fc',
        body3: '#36434D',
        body4: '#f7f9fa',
        body5: '#173145',
        font: '#172f44',
        font2: '#5e6971',
        font3: '#455f70',
        font4: '#5d5d5d',
        font5: '#b0c2cd',
    }
};

export default palette;
