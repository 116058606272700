import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    showData: {
      products: []
    },
    viewData: {
        product: null,
        didUserPurchasedTheCourse: false,
        activeChapter: null,
    },
    lastUpdatedActiveChapter: null,
    relatedProducts: null,
    nextChapterLoading: false,
    nextChapterLoadingProgress: 0,
}

const slice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setShowData(state, action) {
            state.showData.products = action.payload.products;
        },
        setViewData(state, action) {

            let activeChapter = null;
            action.payload.product.sections.forEach(section => {
                section.chapters.forEach(chapter => {
                    if (chapter.id === action.payload.product.last_watched_chapter_id) {
                        activeChapter = chapter
                    }
                });
            });

            state.viewData.product = action.payload.product;
            state.viewData.didUserPurchasedTheCourse = action.payload.did_user_purchased_the_course;
            state.viewData.activeChapter = activeChapter;
        },
        resetViewData(state) {
            state.viewData.product = initialState.viewData.product;
            state.viewData.didUserPurchasedTheCourse = initialState.viewData.didUserPurchasedTheCourse;
            state.viewData.activeChapter = initialState.viewData.activeChapter;
        },
        setActiveChapter(state, action) {
            let activeChapter = null;
            state.viewData.product.sections.forEach(section => {
                section.chapters.forEach(chapter => {
                    if (chapter.id === action.payload.chapterId) {
                        activeChapter = chapter
                    }
                });
            });
            state.viewData.activeChapter = activeChapter;
        },
        setNextChapterAsActiveChapter(state, action) {
            let activeChapter = null;
            state.viewData.product.sections.forEach(section => {
                const currentIndex = section.chapters.indexOf(action.payload.chapterId);
                const nextIndex = (currentIndex + 1) % section.chapters.length;
                if (nextIndex !== currentIndex) {
                    activeChapter = section.chapters[nextIndex];
                    state.viewData.activeChapter = activeChapter;
                }
                /*section.chapters.forEach(chapter => {
                    const currentIndex = items.indexOf(currentItem);
                    const nextIndex = (currentIndex + 1) % items.length;
                    items[nextIndex];
                });*/
            });
            state.viewData.activeChapter = activeChapter;
        },
        setLastUpdateActiveChapter(state, action) {
            state.lastUpdatedActiveChapter = action.payload.updatedActiveChapter;
        },
        updateProduct(state, action) {
            if (state.lastUpdatedActiveChapter) {
                let tempProduct = JSON.parse(JSON.stringify(state.viewData.product));
                tempProduct.sections.forEach(section => {
                    section.chapters.forEach(chapter => {
                        if (chapter.id === action.payload.chapterId) {
                            chapter.watched_duration = state.lastUpdatedActiveChapter.watched_duration;
                            chapter.is_completed = state.lastUpdatedActiveChapter.is_completed;
                        }
                    });
                });
                state.viewData.product = tempProduct;
            }
        },
        setRelatedProducts(state, action) {
            state.relatedProducts = action.payload.related_products
        },
        toggleBookmark(state, action) {
            if (action.payload.slice === 'relatedProducts') {

                let relatedProductsClone = JSON.parse(JSON.stringify(state.relatedProducts))

                if (relatedProductsClone && relatedProductsClone.length) {
                    let targetProductIndex = relatedProductsClone.findIndex(product => {
                        return product.product_id === action.payload.productId
                    });

                    if (targetProductIndex !== -1) {
                        relatedProductsClone[targetProductIndex].is_bookmarked = action.payload.status;
                        state.relatedProducts = relatedProductsClone;
                    }
                }
            } else if (action.payload.slice === 'allProducts') {

                let allProductsClone = JSON.parse(JSON.stringify(state.showData.products))

                if (allProductsClone && allProductsClone.length) {
                    let targetProductIndex = allProductsClone.findIndex(product => {
                        return product.product_id === action.payload.productId
                    });

                    if (targetProductIndex !== -1) {
                        allProductsClone[targetProductIndex].is_bookmarked = action.payload.status;
                        state.showData.products = allProductsClone;
                    }
                }
            }
        },
        setNextChapterLoading(state, action) {
            state.nextChapterLoading = action.payload.loadingState;
        },
        setNextChapterLoadingProgress(state, action) {
            state.nextChapterLoadingProgress = action.payload.loadingProgress;
        }
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            if (action.payload.product.showData.products) {
                state.showData.products = action.payload.product.showData.products
            }
            /*if (action.payload.product.viewData.product) {
                let activeChapter = null;
                action.payload.product.viewData.product.sections.forEach(section => {
                    section.chapters.forEach(chapter => {
                        if (chapter.id === action.payload.product.viewData.product.last_watched_chapter_id) {
                            activeChapter = chapter
                        }
                    });
                });

                state.viewData.product = action.payload.product.viewData.product;
                state.viewData.didUserPurchasedTheCourse = action.payload.product.viewData.didUserPurchasedTheCourse;
                state.viewData.activeChapter = activeChapter;
            }*/
        }
    },
});

export const productActions = slice.actions;

export default slice.reducer;
