import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showData: {
        affiliateVisits: [],
        pagination: null,
    },
    affiliateCookie: null,
}

const slice = createSlice({
    name: 'affiliateVisit',
    initialState,
    reducers: {
        setCookieState(state, action) {
            state.affiliateCookie = action.payload.cookie;
        },
        unsetCookieState(state, action) {
            state.affiliateCookie = initialState.affiliateCookie;
        },
        setShowData(state, action) {
            state.showData.affiliateVisits = action.payload.affiliate_visits.data;
            state.showData.pagination = action.payload.affiliate_visits.pagination;
        },
        resetShowData(state, action) {
            state.showData = initialState.showData;
        },
    },
});

export const affiliateVisitActions = slice.actions;

export default slice.reducer;
