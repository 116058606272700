import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    viewData: {
        chapter: null,
        syllabus: null
    }
}

const slice = createSlice({
    name: 'chapter',
    initialState,
    reducers: {
        setViewData (state, action) {
            state.viewData.chapter = action.payload.chapter;
            state.viewData.syllabus = action.payload.syllabus;
        },
    },
});

export const chapterActions = slice.actions;

export default slice.reducer;
