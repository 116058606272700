import {historyActions} from "./slice";
import axiosInstance from "@helper/axios";
import {uiActions} from "@store/ui/slice";
import {bookmarkActions} from "../Bookmark/slice";
import {setSnackbar2} from "@store/ui/actions";

const basePath = '/history';

export const show = () => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.get(`${basePath}/show`, {
            params: {
                conversion: '1280x720'
            }
        })
            .then(response => {
                dispatch(historyActions.setShowData(response));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log(error.response);
                if (status === 404) {
                    dispatch(bookmarkActions.resetShowData());
                }
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const store = (product_id) => {
    return dispatch => {
        axiosInstance.post(`${basePath}/${product_id}/store`)
            .then(response => {
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}

export const destroy = (productId) => {
    return dispatch => {

        //dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/${productId}/delete`)
            .then(response => {
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}

export const markProductComplete = (productId) => {
    return dispatch => {
        dispatch(setSnackbar2('Class marked as complete'))
        dispatch(historyActions.toggleMarkAsComplete({productId, status: true}));
        dispatch(store(productId));
    }
}

export const unmarkProductComplete = (productId) => {
    return dispatch => {
        dispatch(setSnackbar2('Class marked as in progress'))
        dispatch(historyActions.toggleMarkAsComplete({productId, status: false}));
        dispatch(destroy(productId));
    }
}

export const resetUserWatchHistory = (productId) => {
    return dispatch => {

        dispatch(historyActions.resetUserWatchHistory({productId}));
        dispatch(setSnackbar2('Class Removed'))

        axiosInstance.post(`${basePath}/${productId}/resetUserWatchHistory`)
            .then(response => {
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}
