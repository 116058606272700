import {affiliateVisitActions} from "./slice";
import {uiActions} from "@store/ui/slice";
import axiosInstance from "@helper/axios";
import {createAffiliateCookie} from "@helper/affiliateCookie";

const basePath = "/affiliateVisits";

export const show = (formData) => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.get(`${basePath}/show`, {
            params: formData
        })
            .then(response => {
                dispatch(affiliateVisitActions.setShowData(response));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const store = (formData) => {

    return (dispatch) => {
        dispatch(uiActions.setPendingRequest({pendingRequest: true}));

        axiosInstance
            .post(`${basePath}/store`, formData)
            .then((response) => {
                dispatch(uiActions.setPendingRequest({pendingRequest: false}));
                if (response.visit_id) {
                    let cookieObject = {affiliateTag: formData.tag, visitId: response.visit_id};
                    let cookieCreated = createAffiliateCookie(
                        'affiliateTag',
                        cookieObject,
                        30
                    );
                    if (cookieCreated) {
                        dispatch(affiliateVisitActions.setCookieState({cookie: cookieObject}));
                    }
                }
            })
            .catch(([error, status]) => {
                console.log(error);
                dispatch(uiActions.setPendingRequest({pendingRequest: false}));
            });
    };
};
