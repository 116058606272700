import axios from "axios";
import {UnsetAccessToken} from "@store/Auth/actions";
import {getApiUrl} from "@helper/constants/constant";

let baseURL = '';
if (typeof window !== 'undefined') {
    //baseURL = `${window.location.protocol}//${window.location.hostname}/portzilla/api`;
    //baseURL = `${window.location.protocol}//139.59.34.105:2083/api`;
    //baseURL = `${window.location.protocol}//127.0.0.1:2083/api`;
}
const axiosInstance = axios.create({
    baseURL: `${getApiUrl()}/api`
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
//axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
//axiosInstance.defaults.headers.post['Origin'] = 'http://127.0.0.1:3000'

axiosInstance.interceptors.response.use(
    response => response.data,
    err => {

        if (err.response.status === 401) {
            UnsetAccessToken();
            window.location = `${window.location.origin}/login`
        }

        if (err.response.data) {
            throw [err.response.data, err.response.status]
        }
        throw [err.response, err.response.status]
    }
);

export default axiosInstance;
