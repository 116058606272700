//React, React Router, Formik
import React, {useState} from 'react';
import {PRODUCT} from "@helper/constants/routes";
import {passRouteParams} from "@helper/functions";
import Link from "next/link";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from "@material-ui/icons/Search";
import {InputBase} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from '@material-ui/lab/Autocomplete';

//Redux
import {useDispatch, useSelector} from "react-redux";
import {getProductList, clearProductList} from "@store/ui/actions";
import CloseIcon from "@material-ui/icons/Close";

//Assets

const useStyles = makeStyles(theme => ({
    autocomplete: {
        display: 'inline-block',
        "& > div": {
            position: 'relative'
        }
    },
    listbox: {
        maxHeight: '75vh',
        overflow: 'auto',
        boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 8px',
        borderRadius: '8px',
        width: '100%',
        minWidth: '350px',
        marginTop: 4,
        padding: theme.spacing(4, 0, 6),
        fontSize: '15px'
    },
    option: {
        "&[data-focus='true']": {
            backgroundColor: theme.palette.grey.iron
        },
        "& a": {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            width: '100%',
            "&:visited": {
                color: theme.palette.primary.main
            }
        }
    },
    noOptions: {
        display: 'none'
    },
    closeIcon: {
        color: '#FFF',
        position: 'absolute',
        right: 7,
        top: 7,
        cursor: 'pointer',
        zIndex: 1
    },
    inputInput: {
        width: 400,
        fontSize: '16px',
        border: `1px solid #FFF`,
        height: '40px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        position: 'relative',
        background: "transparent",
        marginLeft: theme.spacing(5),
        color: "#FFF",
        opacity: 1,
        padding: theme.spacing(1, 9, 1, 4),
        transition: theme.transitions.create('width'),
        "&::placeholder": {
            color: '#FFF'
        }
    },
}));

const SearchInput = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');

    const productList = useSelector(state => state.ui.productList);

    const handleSearchInput = (event, value, reason) => {

        setSearchText(value);

        let timer = null;
        if (value.length >= 3) {
            timer = setTimeout(() => {
                dispatch(getProductList(value))
            }, 500);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }

    const handleRenderOption = (option, state) => {
        let [renderedOption] = productList.filter(product => product.title === option);
        return (
            <Link href={{
                pathname: PRODUCT.view,
                query: {slug: renderedOption.slug}
            }}>
                <a>{option}</a>
            </Link>
        );
    }

    const handleClearText = () => {
        setSearchText('');
        dispatch(clearProductList());
    }

    return (
        <Autocomplete
            debug
            id="search-input"
            className={classes.autocomplete}
            classes={{
                listbox: classes.listbox,
                option: classes.option,
                noOptions: classes.noOptions,
                input: classes.inputInput,
            }}
            freeSolo
            options={productList?.map(option => option.title) || []}
            clearOnBlur={false}
            closeIcon={<ClearIcon />}
            autoComplete
            autoHighlight
            noOptionsText={null}
            onInputChange={(event, value, reason) => handleSearchInput(event, value, reason)}
            value={searchText}
            //getOptionLabel={(option) => handleGetOptionLabel(option)}
            renderOption={(option, state) => handleRenderOption(option, state)}
            renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                    {searchText ? <ClearIcon className={classes.closeIcon} onClick={handleClearText}/> : null}
                    <input type="text" {...params.inputProps} placeholder="Which book you wanna finish today?"/>
                </div>
            )}
        />
    );
};

export default SearchInput;
