//React, React Router, Formik
import React, {useState} from 'react';
import {passRouteParams} from "@helper/functions";
import Link from "next/link";
import {PRODUCT} from "@helper/constants/routes";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Modal} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Redux
import {useDispatch, useSelector} from "react-redux";
import {clearProductList, getProductList} from "@store/ui/actions";
import CloseIcon from "@material-ui/icons/Close";

//Assets

const useStyles = makeStyles(theme => ({
    modal: {
        background: '#FFF',
    },
    content: {
        padding: theme.spacing(3, 0, 2),
    },
    modalCloseBtn: {
        width: 'fit-content',
        padding: theme.spacing(0, 2, 0, 6),
        "& .MuiButton-root": {
            color: theme.palette.grey.corduroy,
            "&:hover": {
                color: theme.palette.grey.limed_spruce,
                textDecoration: 'none'
            }
        }
    },
    autocomplete: {
        display: 'inline-block',
        "& > div": {
            position: 'relative'
        }
    },
    popper: {
        width: '100%',
        transform: 'translateX(0)',
        margin: theme.spacing(0, 5)
    },
    listbox: {
        maxHeight: '75vh',
        overflow: 'auto',
        boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 8px',
        borderRadius: '8px',
        width: '100%',
        minWidth: '350px',
        marginTop: 4,
        padding: theme.spacing(4, 0, 6),
        fontSize: '15px'
    },
    option: {
        "&[data-focus='true']": {
            backgroundColor: theme.palette.grey.iron
        },
        "& a": {
            textDecoration: 'none',
            width: '100%',
            "&:visited": {
                color: theme.palette.primary.main,
            }
        }
    },
    noOptions: {
        display: 'none'
    },
    closeIcon: {
        color: '#FFF',
        position: 'absolute',
        right: 7,
        top: 7,
        cursor: 'pointer',
        zIndex: 1
    },
    inputInput: {
        width: '100%',
        fontSize: '16px',
        border: `1px solid #000`,
        height: '40px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        position: 'relative',
        background: "transparent",
        marginLeft: theme.spacing(5),
        color: "#000",
        opacity: 1,
        padding: theme.spacing(1, 9, 1, 4),
        transition: theme.transitions.create('width'),
        "&::placeholder": {
            color: '#000'
        }
    },
    optionLink: {
        color: [theme.palette.primary.main, '!important']
    }
}));

const SearchOverlay = ({searchOverlayModalOpen, searchOverlayModalCloseHandler}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');

    const productList = useSelector(state => state.ui.productList);

    const handleSearchInput = (event, value, reason) => {

        if (reason === 'reset') {
            searchOverlayModalCloseHandler();
        }

        if (reason === 'input') {
            setSearchText(value);

            let timer = null;
            if (value.length >= 3) {
                timer = setTimeout(() => {
                    dispatch(getProductList(value))
                }, 500);
            }
            return () => {
                if (timer) {
                    clearTimeout(timer);
                }
            };
        }
    }

    const handleRenderOption = (option, state) => {
        let [renderedOption] = productList.filter(product => product.title === option);
        return (
            <Link className={classes.optionLink} href={{
                pathname: PRODUCT.view,
                query: {slug: renderedOption.slug}
            }}>
                <a>{option}</a>
            </Link>
        );
    }

    const handleClearText = () => {
        setSearchText('');
        dispatch(clearProductList());
    }

    return (
        <Modal
            className={classes.modal}
            open={searchOverlayModalOpen}
            // onClose={handleClose}
            hideBackdrop
        >
            <Grid container wrap="nowrap" alignItems="center" className={classes.content}>

                <Grid item style={{width: '100%'}}>
                    <Autocomplete
                        debug
                        id="search-input"
                        className={classes.autocomplete}
                        classes={{
                            listbox: classes.listbox,
                            option: classes.option,
                            noOptions: classes.noOptions,
                            input: classes.inputInput,
                            popper: classes.popper
                        }}
                        fullWidth
                        freeSolo
                        options={productList?.map(option => option.title) || []}
                        clearOnBlur={false}
                        closeIcon={<ClearIcon/>}
                        autoComplete
                        autoHighlight
                        noOptionsText={null}
                        onInputChange={(event, value, reason) => handleSearchInput(event, value, reason)}
                        value={searchText}
                        Men
                        renderOption={(option, state) => handleRenderOption(option, state)}
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                                {searchText ?
                                    <ClearIcon className={classes.closeIcon} onClick={handleClearText}/> : null}
                                <input type="text" {...params.inputProps}
                                       placeholder="Which book you wanna finish today?"/>
                            </div>
                        )}
                    />
                </Grid>

                <Grid item className={classes.modalCloseBtn}>
                    <Button variant="text" onClick={searchOverlayModalCloseHandler}>
                        Cancel
                    </Button>
                </Grid>

            </Grid>
        </Modal>
    );
};

export default SearchOverlay;
