//React, React Router, Formik
import React from 'react';
import Link from "next/link";
import {PRODUCT} from "@helper/constants/routes";
import {useRouter} from "next/router";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//Redux
import {useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    menuButton: {
        "& .MuiButton-label": {
            color: "#FFF",
            fontSize: '15px',
            fontWeight: 'bold',
            "& .MuiButton-endIcon": {
                marginLeft: 0,
                "& .MuiSvgIcon-root": {
                    fill: '#FFF'
                }
            },
            "&:hover": {
                color: theme.palette.secondary.main,
            }
        },
        "&:hover": {
            color: theme.palette.secondary.main,
            textDecoration: 'none !important'
        }
    },
    menu: {
        ...theme.typography.lmsPopoverMenu1,
        "& .MuiPaper-root": {
            ...theme.typography.lmsPopoverMenu1["& .MuiPaper-root"],
        }
    },
    menuPaper: {
        marginTop: theme.spacing(1),
    },
    menuList: {
        "& .MuiMenuItem-root": {
            width: '224px',
            "& a": {
                color: theme.palette.primary.main,
                textDecoration: 'none',
                "&:visited": {
                    color: theme.palette.primary.main,
                }
            }
        }
    }

}));

const BrowseMenu = () => {

    const classes = useStyles();
    const router = useRouter();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const categories = useSelector(state => state.siteDetail.categories);
    const {isDBCDomain} = useSelector(state => state.ui);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCategoryClick = (categoryId) => {
        handleClose();
        let search = categoryId ? `?category=${categoryId}` : ''
        router.push(`${PRODUCT.show}${search}`);
    };

    return (

        //Showing browse button as menu if domain is ovb
        //and showing browse button as button (without menu) if domain is zeeshanshaikh
        //This code has no use right now because hiding browse button for zeeshanshaikh in Hedaer component
        !isDBCDomain ?
            <>
                <Button
                    className={classes.menuButton}
                    aria-controls="browse-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    endIcon={<ExpandMoreIcon/>}
                >
                    Browse
                </Button>
                <Menu
                    className={classes.menu}
                    id="browse-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                    getContentAnchorEl={null}
                    classes={{
                        paper: classes.menuPaper,
                        list: classes.menuList
                    }}
                >
                    <MenuItem
                        value='all_video_books'
                        onClick={(event) => handleCategoryClick('')}
                    >
                        All VideoBooks
                    </MenuItem>
                    {
                        categories && categories.map(category => (
                            <MenuItem
                                key={category.id}
                                value={category.id}
                                onClick={(event) => handleCategoryClick(category.id)}
                            >
                                {category.name}
                            </MenuItem>
                        ))
                    }
                </Menu>
            </> :
            <Link href={PRODUCT.show}>
                <Button
                    className={classes.menuButton}
                    component="a"
                >
                    Browse
                </Button>
            </Link>
    );
};

export default BrowseMenu;
