import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    createData: {
        order: null,
        paymentMethods: null,
        actualOrderPrice: null,
        currency: null
    }
}

const slice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setCreateData(state, action) {
            state.createData.order = action.payload.order
            state.createData.paymentMethods = action.payload.payment_methods
            state.createData.actualOrderPrice = action.payload.actual_order_price
            state.createData.currency = action.payload.currency
        },
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            state.createData.order = action.payload.order.createData.order
            state.createData.paymentMethods = action.payload.order.createData.paymentMethods
            state.createData.actualOrderPrice = action.payload.order.createData.actualOrderPrice
            state.createData.currency = action.payload.order.createData.currency
        }
    }
});

export const orderActions = slice.actions;

export default slice.reducer;
