import {alpha, createTheme} from '@material-ui/core/styles';
import palette from "./palette/skill_palette";
import typography from "./typography/gt_typography";

let theme ={
    breakpoints: {
        values: {
            //xxs: 399
            xs: 0,
            sm: 600,
            md: 768,//250
            lg: 992,
            xl: 1200,
        },
    },
    typography,
    spacing: 4,

    /*Overrides*/
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    backgroundColor: palette.grey.wild_sand,
                    color: palette.primary.main,
                    fontSize: '15px',
                    lineHeight: '20px',
                    "&.spinner": {
                        overflow: 'hidden',
                    }
                },
                a: {
                    color: 'inherit',
                    textDecoration: 'none',
                    "&:hover": {
                        textDecoration: 'underline'
                    },
                    "&:visited": {
                        color: 'initial'
                    }
                },
                "WebkitLink": {
                    color: palette.primary.main,
                }
            },
        },
        MuiLink: {
            root: {
                // color: palette.grey.persian_blue,
            }
        },
        MuiButton: {
            root: {
                textTransform: "none",
                color: palette.primary.main,
                fontSize: '15px',
                fontWeight: 'bold',
                lineHeight: '20px',
                padding: '0 20px',
                borderRadius: '4px',
                height: 32,
            },
            text: {
                color: palette.grey.persian_blue,
                padding: '1px 6px',
                "&:hover": {
                    backgroundColor: 'unset',
                    textDecoration: 'underline',
                }
            },
            textPrimary: {
                color: '#FFF',
                padding: '0 9px',
                "&:hover": {
                    color: palette.primary.dark,
                    backgroundColor: 'unset',
                }
            },
            textSecondary: {
                color: '#FFF',
                padding: '0 9px',
                "&:hover": {
                    color: palette.secondary.main,
                    backgroundColor: 'unset',
                }
            },
            contained: {
                boxShadow: 'none',
                "&:hover": {
                    boxShadow: 'none',
                }
            },
            containedSecondary: {
                "&:hover": {
                    backgroundColor: palette.secondary.dark,
                }
            },
            containedSizeLarge: {
                fontSize: '18px',
                height: '40px',
                // lineHeight: 38,
                minWidth: '96px'
            },
            sizeLarge: {
                fontSize: '18px',
            },
            outlinedPrimary: {
                "&:hover": {
                    backgroundColor: palette.primary.main,
                    color: '#FFF',
                }
            }
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: 'unset'
                }
            }
        },
        MuiRadio: {
            colorPrimary: {
                "&$checked": {
                    color: palette.grey.persian_blue
                }
            }
        },

        MuiFormControl: {
            root: {
                "& .MuiFormLabel-root": {
                    //color: palette.grey.blue_bayoux,
                },
                "& .MuiFormHelperText-root": {
                    marginLeft: 0
                },
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '13px',
                lineHeight: '20px',
                color: palette.primary.main,
            },
        },
        MuiOutlinedInput: {
            root: {
                '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${palette.grey.iron}`,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: [`1px solid ${palette.grey.persian_blue}`, '!important']
                },
                "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette.grey.iron}`,
                    },
                },
            },
            input: {
                width: '100%',
                height: 38,
                padding: '0 14px',
                borderRadius: '4px',
                /*Not Working*/
                "&::placeholder": {
                    color: palette.primary.main,
                }
            }
        },
        MuiSelect: {
            selectMenu: {
                whiteSpace: 'unset'
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: alpha('#000', 0.8),
                cursor: 'zoom-out',
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: '24px',
            }
        }
    }
};

export default theme;
