import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showData: {
        bookmarks: null
    }
}

const slice = createSlice({
    name: 'bookmark',
    initialState,
    reducers: {
        setShowData(state, action) {
            state.showData.bookmarks = action.payload.bookmarks;
        },
        resetShowData(state) {
            state.showData.bookmarks = initialState.showData.bookmarks;
        },
        toggleMarkAsComplete (state, action) {
            let products = JSON.parse(JSON.stringify(state.showData.bookmarks))

            if (products && products.length) {
                let targetProductIndex = products.findIndex(product => {
                    return product.product_id === action.payload.productId
                });

                if (targetProductIndex !== -1) {
                    products[targetProductIndex].is_completed = action.payload.status;
                    state.showData.bookmarks = products;
                }
            }
        },
        removeBookmark(state, action) {
            let products = JSON.parse(JSON.stringify(state.showData.bookmarks))

            if (products && products.length) {
                let targetProductIndex = products.findIndex(product => {
                    return product.product_id === action.payload.productId
                });

                if (targetProductIndex !== -1) {
                    products.splice(targetProductIndex, 1);
                    state.showData.bookmarks = products;
                }
            }
        },
    },
});

export const bookmarkActions = slice.actions;

export default slice.reducer;
