//React, React Router, Formik
import React, {useEffect} from 'react';
import {useFormik} from "formik";
import {useRouter} from "next/router";
import getValidationObject from "@request/Auth/Register/StoreRegisterRequest";
import useValidationMessages from "@util/hooks/useValidationMessages/useValidationMessages";
import ErrorText from "@ui/ErrorText/ErrorText";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {FormControl, Grid, OutlinedInput} from "@material-ui/core";

//Redux
import {useDispatch, useSelector} from "react-redux";
import {register, validationMessages as getValidationMessages} from "@store/Auth/actions";
import CircularProgress from "@material-ui/core/CircularProgress";

//Assets

const useStyles = makeStyles(theme => ({
    fieldRow: {
        marginBottom: theme.spacing(3),
        width: '100%',
    },
    formControl: {
        width: '48%',
    },
    formHelperText: {
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            textAlign: 'initial',
        }
    },
    inputRoot: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.grey.iron}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: [`1px solid ${theme.palette.grey.persian_blue}`, '!important']
        },
        "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${theme.palette.grey.iron}`,
            },
        },
    },
    inputInput: {
        width: '100%',
        height: 38,
        padding: theme.spacing(0, 3.5),
        borderRadius: '4px',
        backgroundColor: '#FFF',
        /*Not Working*/
        "& ::-webkit-input-placeholder": {
            color: theme.palette.primary.main,
        },
        "& ::-moz-input-placeholder": {
            color: theme.palette.primary.main,
        },
        "& :-ms-input-placeholder": {
            color: theme.palette.primary.main,
        },
    },
    mobileInput: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    },
    signupBtnWrapper: {
        width: '100%',
        "& .MuiButton-root": {
            "&:hover": {
                backgroundColor: theme.palette.secondary.darker
            }
        }
    },
    pendingSignUpButton: {
        backgroundColor: theme.palette.grey.iron,
        borderColor: theme.palette.grey.iron,
        "&:hover": {
            backgroundColor: [theme.palette.grey.iron, '!important']
        }
    },
}));

const SignupForm = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();

    const errorApiResponse = useSelector(state => state.ui.apiResponse.formValidation);
    const {pendingRequest, isZSDomain} = useSelector(state => state.ui);
    const {defaultProduct} = useSelector(state => state.siteDetail);

    //Getting validation object
    /*const validationObject = useValidationMessages(
        () => getValidationMessages('CREATE'),
        getValidationObject
    );*/

    //Setting form validation errors
    useEffect(() => {
        if (errorApiResponse) {
            Object.keys(errorApiResponse).map(function (key) {
                formik.setFieldError(key, errorApiResponse[key][0]);
            })
        }
    }, [errorApiResponse]);

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            isd_code: '+91',
            mobile_number: ''
        },
        onSubmit: values => {
            dispatch(register(values, router, isZSDomain, defaultProduct?.id));
        },
        //validationSchema: validationObject,
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Grid container>

                <ErrorText
                    show={
                        formik.touched.first_name && Boolean(formik.errors.first_name) ||
                        formik.touched.last_name && Boolean(formik.errors.last_name)
                    }
                    message={
                        formik.errors.first_name ||
                        formik.errors.last_name
                    }
                />

                <Grid item container wrap="nowrap" justifyContent="space-between" className={classes.fieldRow}>

                    <FormControl variant="outlined" className={classes.formControl}>

                        <OutlinedInput
                            id="outlined-first-name"
                            labelWidth={0}
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{
                                placeholder: 'First Name'
                            }}
                        />
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <OutlinedInput
                            id="outlined-last-name"
                            labelWidth={0}
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{
                                placeholder: 'Last Name'
                            }}
                        />
                    </FormControl>
                </Grid>

                <ErrorText
                    show={formik.touched.email && Boolean(formik.errors.email)}
                    message={formik.errors.email}
                />

                <Grid item className={classes.fieldRow}>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            id="outlined-email"
                            labelWidth={0}
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{
                                placeholder: 'Email'
                            }}
                        />
                    </FormControl>
                </Grid>

                <ErrorText
                    show={formik.touched.password && Boolean(formik.errors.password)}
                    message={formik.errors.password}
                />

                <Grid item className={classes.fieldRow}>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            labelWidth={0}
                            name="password"
                            value={formik.values.password}
                            type="password"
                            onChange={formik.handleChange}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{
                                placeholder: 'Password'
                            }}
                        />
                        {/*<FormHelperText className={classes.formHelperText}>
                            Password must be at least 4 characters long.
                        </FormHelperText>*/}
                    </FormControl>
                </Grid>

                <ErrorText
                    show={
                        formik.touched.isd_code && Boolean(formik.errors.isd_code) ||
                        formik.touched.mobile_number && Boolean(formik.errors.mobile_number)
                    }
                    message={
                        formik.errors.isd_code ||
                        formik.errors.mobile_number
                    }
                />

                <Grid item container wrap="nowrap" justifyContent="space-between" className={classes.fieldRow} style={{gap: '8px'}}>

                    <FormControl variant="outlined" style={{width: '25%'}}>
                        <OutlinedInput
                            labelWidth={0}
                            name="isd_code"
                            value={formik.values.isd_code}
                            type="text"
                            onChange={formik.handleChange}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{
                                placeholder: 'Country Code'
                            }}
                        />
                    </FormControl>

                    <FormControl variant="outlined" style={{width: '75%'}}>
                        <OutlinedInput
                            labelWidth={0}
                            name="mobile_number"
                            value={formik.values.mobile_number}
                            type="number"
                            onChange={formik.handleChange}
                            className={classes.mobileInput}
                            classes={{
                                root: classes.inputRoot,
                                input: `${classes.inputInput} ${classes.mobileInput}`,
                            }}
                            inputProps={{
                                placeholder: 'Mobile Number'
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item className={classes.signupBtnWrapper}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        type="submit"
                        className={pendingRequest ? classes.pendingSignUpButton : classes.signInButton}
                        endIcon={pendingRequest ? <CircularProgress size={18}/> : undefined}
                    >
                        {!pendingRequest && 'Sign Up'}
                    </Button>
                </Grid>

            </Grid>
        </form>
    );
};

export default SignupForm;
