//React, React Router, Formik
import React from 'react';
import Image from "next/image";
//import Image from "next/future/image";

//Material UI
import {makeStyles} from '@material-ui/core/styles';

//Redux

//Assets

const useStyles = makeStyles(theme => ({
}));

const CustomImage = ({loaded, ...props}) => {

    const classes = useStyles();

    return (
        loaded ? <Image {...props} className="custom-image"/> : null
    );
};

export default CustomImage;
