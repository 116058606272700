import * as Yup from "yup";

function getValidationObject(messages) {

    let validationObject = null;


    if (messages) {
        validationObject = Yup.object().shape({
            first_name: Yup.string()
                .trim()
                .required(messages["missing_first_name"].text)
                .max(50, messages["maximum_first_name_length"].text),
            last_name: Yup.string()
                .trim()
                .required(messages["missing_last_name"].text)
                .max(50, messages["maximum_last_name_length"].text),
            email: Yup.string()
                .trim()
                .required(messages["missing_email"].text)
                .email(messages["invalid_email"].text)
                .max(50, messages["maximum_email_length"].text),
            password: Yup.string()
                .trim()
                .required(messages["missing_password"].text)
                .min(4, messages["minimum_password_length"].text)
                .max(255, messages["maximum_password_length"].text),
            isd_code: Yup.string()
                .required(messages["missing_isd_code"].text)
                .max(50, messages["maximum_isd_code_length"].text),
            mobile_number: Yup.number()
                .typeError(messages['invalid_mobile_number'].text)
                .required(messages["missing_mobile_number"].text),
        });
    }

    return validationObject;
}

export default getValidationObject;
