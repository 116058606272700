import {authActions} from "./slice";
import {uiActions} from "../ui/slice";
import {userActions} from "@store/Operator/User/slice";
import axiosInstance from "@helper/axios";
import {AUTH, CHECKOUT, PRODUCT, publicRoutes} from "@helper/constants/routes";
import {setSnackbar2} from "@store/ui/actions";
import {destroyCookie, setCookie} from "nookies";

const basePath = '/auth';

export const validationMessages = (formType) => {
    return dispatch => {

        axiosInstance.get(`${basePath}/validationMessages`, {
            params: {'form_type': formType}
        })
            .then(response => {
                dispatch(uiActions.setErrorMessages({
                    messages: response.messages
                }))
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const register = (formData, router, isZSDomain, defaultProductId) => {
    return dispatch => {

        dispatch(uiActions.setPendingRequest({pendingRequest: true}));

        axiosInstance.post(`${basePath}/register`, formData)
            .then(response => {
                dispatch(makeUserLogin(response.access_token, response.user));
                dispatch(uiActions.setPendingRequest({pendingRequest: false}));
                dispatch(sendEmailVerificationMail(response.user.id));

                if (isZSDomain && defaultProductId) {
                    let path = `${CHECKOUT.show}?product=${defaultProductId}`;
                    console.log('path: ', path);
                    router.push(path);
                } else {
                    console.log('else part');
                    router.push(`${CHECKOUT.show}`)
                }
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                dispatch(uiActions.setPendingRequest({pendingRequest: false}));
                console.log(error.response);
            })
    }
}

export const sendEmailVerificationMail = (userId) => {
    return dispatch => {
        axiosInstance.post(`${basePath}/${userId}/sendMail`)
            .then(response => {
                console.log('Success');
            })
            .catch(([error, status]) => {
                console.log(error);
            })
    }
}

export const login = (credential, router, isDBCDomain = null, defaultProductSlug) => {
    return dispatch => {

        dispatch(uiActions.setPendingRequest({pendingRequest: true}));

        axiosInstance.post(`${basePath}/login`, credential)
            .then(response => {
                dispatch(makeUserLogin(response.access_token, response.user, router));
                dispatch(uiActions.setPendingRequest({pendingRequest: false}));

                //Redirect to product page
                if (isDBCDomain && response.user.has_membership) {
                    router.push({
                        pathname: PRODUCT.view,
                        query: {slug: defaultProductSlug}
                    })
                } else {
                    //After user logs in or logs out, the content of public route
                    //should update based on user auth status
                    router.replace(router.asPath);
                }
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                dispatch(uiActions.setPendingRequest({pendingRequest: false}));
                console.log(error.response);
            })
    }
}

export const getAuthUser = () => {
    return dispatch => {
        axiosInstance.get(`${basePath}/getAuthUser`)
            .then(response => {
                dispatch(
                    authActions.setUser({
                        auth_user: response.user
                    })
                );
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}

export const logout = (router) => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/logout`)
            .then(response => {
                UnsetAccessToken();
                dispatch(authActions.setUnAuthenticated());
                if (publicRoutes.includes(router.pathname)) {
                    router.replace(router.asPath);
                }
                //router.push(AUTH.login);
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log(error.response);
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const makeUserLogin = (access_token, auth_user = null, router = null) => {
    return async dispatch => {
        setAccessToken(access_token);
        await dispatch(authActions.setAuthenticated());

        if (auth_user) {
            dispatch(authActions.setUser({auth_user}));
        } else {
            dispatch(getAuthUser());
        }
    }
}

export const setAccessToken = (token) => {
    const access_token = `Bearer ${token}`;
    setCookie(null, 'access_token', access_token, {
        maxAge: 365 * 24 * 60 * 60,
        path: '/',
    })
    //window.nookie = destroyCookie;
    axiosInstance.defaults.headers.common['Authorization'] = access_token;
}

export const UnsetAccessToken = () => {
    destroyCookie({}, 'access_token', {
        path: '/',
    })
    delete axiosInstance.defaults.headers.common['Authorization'];
}

export const sendForgotPasswordEmail = (formData) => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/sendForgotPasswordEmail`, formData)
            .then(response => {
                dispatch(authActions.setForgotPasswordEmailSent());
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log(error.response);
                dispatch(uiActions.setErrorApiResponse(error));
                dispatch(uiActions.toggleSpinner({spinner: true}));
            })
    }
}

export const resetPassword = (formdata) => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/resetPassword`, formdata)
            .then(response => {
                console.log('PASSWORD RESETTED');
                dispatch(makeUserLogin(response.access_token, response.user));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log(error.response);
                dispatch(uiActions.setErrorApiResponse(error));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const unsetForgotPasswordEmailSent = () => {
    return dispatch => {
        dispatch(authActions.unsetForgotPasswordEmailSent());
    }
}

/*SOCIAL AUTH*/
export const disconnectSocialProvider = (provider) => {

    return dispatch => {
        axiosInstance.get(`${basePath}/${provider}/disconnectSocialProvider`)
            .then(response => {
                dispatch(setSnackbar2(response.message));
                dispatch(userActions.unsetSocialProvider({provider}))
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}

/* Verify Access Token */
export const verifyAccessToken = (accessToken, router) => {

    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        axiosInstance.post(`${basePath}/verifyAccessToken`)
            .then(response => {
                console.log('Verified');
                dispatch(makeUserLogin(accessToken, null, router));
                if (router.pathname === CHECKOUT.authCheckout) {
                    router.push(CHECKOUT.show);
                }
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log('NOT Verified');
                dispatch(uiActions.toggleSpinner({spinner: false}));
                //router.push(AUTH.login);
            })
    }
}


