import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    categories: null,
    bannerStrip: null,
    brandingLogo: null,
    favIcon: null,
    siteTitle: '',
    siteDescription: '',
    socialLinks: null,
    playStoreLink: null,
    appStoreLink: null,
    colorPalette: null,
    assetPath: '',
    appMeta: {},
    defaultProduct: null,
    //googleTagManagerId: ''
}

const slice = createSlice({
    name: 'SiteDetail',
    initialState,
    reducers: {
        setSiteData (state, action) {

            state.categories = action.payload.categories;

            state.brandingLogo = action.payload.branding_logo_media;

            state.favIcon = action.payload.fav_icon_media?.url ?? '';

            state.bannerStrip = action.payload.banner_strip_data;

            state.assetPath = action.payload.asset_path

            state.socialLinks = action.payload.social_links

            state.playStoreLink = action.payload.play_store_link

            state.colorPalette = action.payload.color_palette;

            state.appMeta = action.payload.app_meta;

            state.defaultProduct = action.payload.default_product;

        },
    },
});

export const siteDetailActions = slice.actions;

export default slice.reducer;
