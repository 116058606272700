import {createSlice} from "@reduxjs/toolkit";
import {getCookie} from "@helper/functions"
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    cookieCoupons: [],
    defaultCoupon: null,
    defaultCouponDiscount: 0
}

const slice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        setCookieCoupons(state, action) {
            /*let cookie = getCookie('coupons');
            state.cookieCoupons = cookie ? JSON.parse(cookie) : [];*/

            let tempCookieCoupons = JSON.parse(JSON.stringify(state.cookieCoupons));
            tempCookieCoupons.push(action.payload.coupons);
            state.cookieCoupons = tempCookieCoupons;
        },
        unsetCookieCoupons: (state, action) => {
            state.cookieCoupons = state.cookieCoupons.filter(coupon => coupon.name !== action.payload.couponName);
        },
        setDefaultCoupon(state, action) {
            state.defaultCoupon = action.payload.coupon
        },
        setDefaultCouponDiscount(state, action) {
            state.defaultCouponDiscount = action.payload.discount;
        }
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            if (action.payload.coupon.cookieCoupons) {
                state.cookieCoupons = action.payload.coupon.cookieCoupons;
            }
            if (action.payload.coupon.defaultCoupon) {
                state.defaultCoupon = action.payload.coupon.defaultCoupon
            }
        }
    }
});

export const couponActions = slice.actions;

export default slice.reducer;
