//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {FormHelperText, Grid} from "@material-ui/core";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';


//Redux

//Assets

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(1),
        "& .MuiSvgIcon-root": {
            fill: theme.palette.danger.text,
        }
    },
    text: {
        color: theme.palette.danger.text,
        padding: theme.spacing(3.5, 0),
        position: 'relative',
        fontSize: '13px',
        fontWeight: 'bold',
    }
}));

const ErrorText = ({show, message}) => {

    const classes = useStyles();

    return (
        show ?
        <Grid container alignItems="center">
            <Grid item className={classes.icon}>
                <RemoveCircleIcon />
            </Grid>
            <Grid item className={classes.text}>
                {message}
            </Grid>
        </Grid> : null
    );
};

export default ErrorText;
