import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    indexData: {
        tag: '',
        visitsCount: 0,
        paidReferralsCount: 0,
        unpaidReferralsCount: 0,
        conversionRate: 0,
        paidEarning: 0,
        unpaidEarning: 0,
        currency: null
    },
    showData: {
        referrals: [],
        pagination: null,
    }
}

const slice = createSlice({
    name: 'affiliate',
    initialState,
    reducers: {
        setIndexData(state, action) {
            state.indexData.tag = action.payload.tag;
            state.indexData.visitsCount = action.payload.visits_count;
            state.indexData.paidReferralsCount = action.payload.paid_referrals_count;
            state.indexData.unpaidReferralsCount = action.payload.unpaid_referrals_count;
            state.indexData.conversionRate = action.payload.conversion_rate;
            state.indexData.paidEarning = action.payload.paid_earning;
            state.indexData.unpaidEarning = action.payload.unpaid_earning;
            state.indexData.currency = action.payload.currency;
        },
        resetIndexData(state, action) {
            state.indexData = initialState.indexData;
        },
        setShowData(state, action) {
            state.showData.referrals = action.payload.referrals.data;
            state.showData.pagination = action.payload.referrals.pagination;
        },
        resetShowData(state, action) {
            state.showData = initialState.showData;
        },
    },
    extraReducers: {
        [HYDRATE] (state, action) {
            state.indexData = action.payload.affiliate.indexData;
        }
    }
});

export const affiliateActions = slice.actions;

export default slice.reducer;
