//React, React Router, Formik
import React, {Fragment, useEffect, useState} from "react";
import SnackBar from "@ui/Snackbar/Snackbar";
import dynamic from "next/dynamic";

const Footer = dynamic(() => import('@layout/Footer/Footer'), {ssr: false});
import Header from "@layout/Header/Header";
import Sidebar from "@layout/Sidebar/Sidebar";
import {CHECKOUT, ORDER, WELCOME, WORKSHOP} from "@helper/constants/routes";
import PurchaseSpinner from "../ui/PurchaseSpinner/PurchaseSpinner";
import {useRouter} from "next/router";
import Head from "next/head";

//Material UI
import {makeStyles} from "@material-ui/core";

//Redux
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    authContainer: {
        width: "100%",
        padding: `0 ${theme.spacing(7.5)}px`,
        margin: 'auto',
        [theme.breakpoints.down("lg")]: {
            width: '100%',
            // maxWidth: '100%',
        },
        [theme.breakpoints.down("md")]: {
            width: '100%',
            maxWidth: '100%',
        },
        [theme.breakpoints.down("sm")]: {
            padding: `0 ${theme.spacing(3.75)}px`,
        },
        /*[theme.breakpoints.up("md")]: {
            width: '540px',
            maxWidth: '100%'
        },*/
        [theme.breakpoints.up("lg")]: {
            width: '960px',
            maxWidth: '100%',
            padding: `0 ${theme.spacing(7.5)}px`,
        },
    },
    contentContainer: {
        maxWidth: "1144px",
        margin: "auto"
    }
}));

const Layout = (props) => {

    const classes = useStyles();
    const router = useRouter();

    const [isMobileApp, setIsMobileApp] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [showFooter, setShowFooter] = useState(true);
    const {isDBCDomain} = useSelector(state => state.ui);

    const isCheckoutPage = router.pathname === CHECKOUT.show;
    const isHomePage = router.pathname === WELCOME.show;
    const isWorkshopPage = router.pathname === WORKSHOP.dbc;

    useEffect(() => {
        if (navigator.userAgent.indexOf('mobile-app') === -1) {
            setIsMobileApp(false);
        } else {
            setIsMobileApp(true);
        }
    }, [])

    const purchaseSpinner = useSelector(state => state.ui.purchaseSpinner);

    useEffect(() => {

        console.log('Layout useeffct called');

        if (isMobileApp) {
            setShowHeader(false);
        } else if (isDBCDomain && (isCheckoutPage || isHomePage || isWorkshopPage)) {
            setShowHeader(false);
        } else {
            setShowHeader(true);
        }

        if (isCheckoutPage) {
            setShowFooter(false);
        } else if ((isDBCDomain && isHomePage) || isWorkshopPage) {
            setShowFooter(false);
        } else {
            setShowFooter(true);
        }
    }, [router.pathname])


    return (
        <Fragment>

            {showHeader && <Header/>}
            {!isDBCDomain && <Sidebar/>}
            <SnackBar/>
            {purchaseSpinner && <PurchaseSpinner/>}

            {props.children}

            {showFooter && <Footer/>}
        </Fragment>
    );
};

export default Layout;
