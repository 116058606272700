import {bookmarkActions} from "./slice";
import axiosInstance from "@helper/axios";
import {uiActions} from "@store/ui/slice";
import {store as storeHistory, destroy as deleteHistory} from "@store/History/actions";
import {setSnackbar2} from "@store/ui/actions";

const basePath = '/bookmarks';

export const show = () => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.get(`${basePath}/show`, {
            params: {
                conversion: '1280x720'
            }
        })
            .then(response => {
                dispatch(bookmarkActions.setShowData(response));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log(error.response);
                if (status === 404) {
                    dispatch(bookmarkActions.resetShowData());
                }
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const store = (productId) => {
    return dispatch => {

        axiosInstance.post(`${basePath}/${productId}/store`)
            .then(response => {
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}

export const destroy = (productId) => {
    return dispatch => {
        dispatch(bookmarkActions.removeBookmark({productId}))
        dispatch(setSnackbar2('Bookmark removed'))
        axiosInstance.post(`${basePath}/${productId}/delete`)
            .then(response => {
            })
            .catch(([error, status]) => {
                console.log(error.response);
            })
    }
}

export const markProductComplete = (productId) => {
    return dispatch => {
        dispatch(setSnackbar2('Class marked as complete'))
        dispatch(bookmarkActions.toggleMarkAsComplete({productId, status: true}));
        dispatch(storeHistory(productId));
    }
}

export const unmarkProductComplete = (productId) => {
    return dispatch => {
        dispatch(setSnackbar2('Class marked as in progress'))
        dispatch(bookmarkActions.toggleMarkAsComplete({productId, status: false}));
        dispatch(deleteHistory(productId));
    }
}
