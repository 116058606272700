//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

//Redux
import {useDispatch, useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    notificationBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(4.5, 11.5, 4.5, 6),
        fontSize: '13px',
        textDecoration: 'none',
        background: '#FFF',
        "&:hover": {
            textDecoration: 'none',
            background: 'inherit',
        },
        "& .MuiTypography-root": {
            fontWeight: 'bold',
        },
        cursor: 'pointer'
    },
    mainText: {
        color: theme.palette.primary.main,
    },
    highlightBtn: {
        color: theme.palette.link.main,
        marginLeft: theme.spacing(0.75),
    },
    icon: {
        fill: '#000'
    }

}));

const NotificationBar = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const {bannerStrip} = useSelector(state => state.siteDetail);

    const getBanner = () => {
        if (bannerStrip) {
            if (isAuthenticated && bannerStrip.auth_banner.enabled) {
                return (
                    <a className={classes.notificationBar} href={`http://${bannerStrip.auth_banner.link}`} >
                        <Typography className={classes.mainText}>
                            {bannerStrip.auth_banner.text}
                        </Typography>
                        <Typography className={classes.highlightBtn}>{bannerStrip.auth_banner.link_text}</Typography>
                        <NavigateNextIcon className={classes.icon}/>

                    </a>
                )
            }

            if (!isAuthenticated && bannerStrip.guest_banner.enabled) {
                return (
                    <a className={classes.notificationBar} href={`http://${bannerStrip.guest_banner.link}`} >
                        <Typography className={classes.mainText}>
                            {bannerStrip.guest_banner.text}
                        </Typography>
                        <Typography className={classes.highlightBtn}>{bannerStrip.guest_banner.link_text}</Typography>
                        <NavigateNextIcon className={classes.icon}/>

                    </a>
                )
            }
        }
        return null;
    }

    return (
        getBanner()
    );
};

export default NotificationBar;
