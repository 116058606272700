//React, React Router, Formik
import React, {useState} from 'react';
import {useRouter} from "next/router";
import Link from "next/link";
import DirectionText from "./DirectionText/DirectionText";
import SocialAuthButton from "../SocialAuthButton/SocialAuthButton";
import SignupForm from "@ui/SignupForm/SignupForm";
import {AUTH} from "@helper/constants/routes";
import SignInForm from "../SignInForm/SignInForm";
import {TERMS_PRIVACY_AND_HELP} from "@helper/constants/routes";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Button, Dialog, Grid, IconButton, Typography} from "@material-ui/core";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {useSelector} from "react-redux";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    dialog: {
        "& .MuiDialog-paper": {
            maxWidth: 668,
            overflow: 'visible',
            [theme.breakpoints.down('sm')]: {
                maxWidth: 'initial',
                margin: theme.spacing(0, 2),
            }
        }
    },
    dialogContent: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    dialogCloseButton: {
        all: 'unset',
        cursor: 'pointer',
        position: 'absolute',
        top: 10,
        right: 10,
        "&:hover": {
            backgroundColor: 'unset',
        },
        "& .MuiSvgIcon-root": {
            fontSize: '32px'
        },
        [theme.breakpoints.down('xs')]: {
            color: '#FFF'
        }
    },
    formRoot: {
        padding: theme.spacing(15, 10, 7.5),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: theme.spacing(7.5, 10, 7.5),
        }
    },
    fpWrapper: {
        margin: theme.spacing(4, 0, 0),
        "& .MuiButton-root": {
            padding: 0
        }
    },
    signUpUsingEmailWrapper: {
        margin: theme.spacing(2, 0, 0),
        textAlign: 'center',
    },
    signupOrSigninTextWrapper: {
        marginTop: theme.spacing(8.5),
        textAlign: 'center',
        "& .MuiTypography-root": {
            fontSize: '15px',
            "& .MuiButton-root": {
                padding: 0
            }
        },

        "&::selection": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main
        },
    },
    separator: {
        margin: theme.spacing(6, 0),
        height: 1,
        borderTop: `1px solid ${theme.palette.primary.main}`
    },
    agreementWrapper: {
        textAlign: 'center',
        "& .MuiTypography-root": {
            fontSize: '13px',
            lineHeight: '16px',
            color: theme.palette.grey.limed_spruce,
            "& a": {
                fontWeight: 'bold',
            }
        }
    }
}));

const AuthModal = ({open, closeHandler, modalMode, modalModeHandler}) => {

    const classes = useStyles();
    const router = useRouter();

    const [showSignUpForm, setShowSignUpForm] = useState(false);

    const {appMeta} = useSelector(state => state.siteDetail);

    const handleShowSignUpForm = () => {
        setShowSignUpForm(true);
    }

    const handleForgotPasswordClick = () => {
        closeHandler();
        router.push(AUTH.forgotPassword);
    }

    return (
        <Dialog
            className={classes.dialog}
            scroll="body"
            onClose={closeHandler}
            aria-labelledby="auth-modal"
            open={open}
        >

            <Grid wrap="nowrap" container className={classes.dialogContent}>

                {/*Direction Text*/}
                <Grid item>
                    <DirectionText modalMode={modalMode}/>
                </Grid>

                {/*Form*/}
                <Grid item container direction="column" className={classes.formRoot}>

                    <IconButton className={classes.dialogCloseButton} onClick={closeHandler}>
                        <CloseOutlinedIcon/>
                    </IconButton>

                    <Grid item>
                        <SocialAuthButton variant="light" borderTopColor/>
                    </Grid>

                    {/*Sign In Or Sign Up Form*/}
                    <Grid item>
                        {
                            modalMode === 'signIn' ?
                                <SignInForm/> :
                                showSignUpForm && <SignupForm/>
                        }
                    </Grid>

                    {/*Sign In Content*/}
                    {
                        modalMode === 'signIn' ?
                            <>
                                <Grid item className={classes.fpWrapper}>

                                    <Button variant="text" onClick={handleForgotPasswordClick}>
                                        Forgot password?
                                    </Button>
                                </Grid>
                            </> :
                            !showSignUpForm &&
                            <Grid item className={classes.signUpUsingEmailWrapper}>
                                <Button variant="text" onClick={handleShowSignUpForm}>
                                    Sign Up Using Email
                                </Button>
                            </Grid>
                    }

                    <Grid item className={classes.signupOrSigninTextWrapper}>
                        {
                            modalMode === 'signUp' ?
                                <Typography>
                                    Already a member? &nbsp; <Button variant="text" onClick={() => modalModeHandler('signIn')}>Sign
                                    In.</Button>
                                </Typography> :
                                <Typography>
                                    Not a member yet? &nbsp; <Button variant="text" onClick={() => modalModeHandler('signUp')}>Sign
                                    Up.</Button>
                                </Typography>
                        }
                    </Grid>


                    {
                        modalMode === 'signUp' &&
                        <>
                            {/*Separator*/}
                            <Grid item>
                                <div className={classes.separator}/>
                            </Grid>

                            {/*Agreement*/}
                            <Grid item className={classes.agreementWrapper}>
                                <Typography>
                                    By signing up you agree to {appMeta?.appName}&apos;s
                                    <Link href={TERMS_PRIVACY_AND_HELP.terms} target="_blank" rel="noreferrer"><a> Terms of Service</a></Link> and <Link
                                    href={TERMS_PRIVACY_AND_HELP.privacy} target="_blank" rel="noreferrer"><a>Privacy Policy</a></Link>. This website is
                                    protected by reCAPTCHA and is subject to Google&apos;s <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of
                                    Service</a> and <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy.</a>
                                </Typography>
                            </Grid>
                        </>
                    }

                </Grid>

            </Grid>

        </Dialog>
    );
};

export default AuthModal;
