import palette from "../palette/skill_palette";
import theme from "../theme";

const h1 = {
    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '44px',
};

const h2 = {
    fontSize: '26px',
    fontWeight: '700',
    lineHeight: '32px',
};

const h3 = {
    fontSize: '22px',
    fontWeight: '700',
    lineHeight: '28px',
};

const h4 = {
    fontSize: '18px',
    lineHeight: '22px',
};

const h5 = {
    fontSize: '15px',
    lineHeight: '20px',
};

const h6 = {
    fontSize: '13px',
    lineHeight: '18px',
};

//Kajabi Body
const body1 = {
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.75rem',
    '@media (min-width:992px)': {
        fontSize: '0.875rem',
        // lineHeight: '1.5rem', //Misaligning textfield label
    },
};

//Kajabi Body Sm
const body2 = {
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    '@media (min-width:992px)': {
        fontSize: '0.8125rem',
    },
};

const subtitle1 = {
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: '1.25rem'
};

const lmsLinkHover = {
    "&:hover": {
        color: palette.grey.limed_spruce,
            backgroundColor: palette.grey.iron,
            borderRadius: '4px'
    }
}

const typography = {
    fontFamily: [
        'GT Walsheim Pro',
        // 'Nunito',
    ].join(','),
    fontSize: 16,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    body1,
    body2,
    subtitle1,

    lmsCard: {
        boxShadow: 'rgb(184 184 184 / 50%) 0px 2px 4px 0px',
        borderRadius: '8px',
        background: '#FFF',
    },

    lmsContainer: {
        maxWidth: '1170px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0 16px',
        Overflow: 'visible',
        '@media (max-width:1199px)': {
            padding: '0 40px',
        },
        '@media (max-width:599px)': {
            padding: '0 20px',
        }
    },
    lmsFullPageContainer: {
        maxWidth: '1400px',
        padding: '32px 24px 0 72px',
        '@media (max-width:1199px)': {
            padding: '32px 24px 0 24px',
        }
    },
    lmsCenterPage: {
        maxWidth: 960,
        margin: '0 auto',
        position: 'relative',
        "@media(max-width: 991px)": {
            maxWidth: 'initial',
            padding: '0 30px'
        }
    },
    lmsCenterPage2: {
        maxWidth: 1000,
        margin: '0 auto 100px',
        padding: '0 30px',
        "@media(max-width: 991px)": {
            padding: '0 60px'
            ,
            "@media(max-width: 767px)": {
                padding: '0 40px'
            },
            "@media(max-width: 600px)": {
                padding: '0 20px'
            }
        }
    },
    lmsCenterPage3: {
        maxWidth: 400,
        margin: '0 auto',
        position: 'relative',
        "@media(max-width: 991px)": {
            maxWidth: 960,
            padding: '0 30px'
        },
    },
    blueButton: {
        backgroundColor: palette.grey.persian_blue,
        color: '#FFF',
        borderRadius: 3,
        height: 35
    },
    carouselArrowButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        zIndex: 999,
        backgroundColor: '#FFF',
        borderRadius: '50%',
        border: '1px solid transparent',
        boxShadow: '0 2px 4px 0 rgb(187 187 187 / 50%)',
        color: palette.grey.iron,
        "&:hover": {
            backgroundColor: '#FFF'
        },
        "& .MuiSvgIcon-root": {
            fill: palette.grey.limed_spruce
        }
    },

    lmsPopoverMenu1: {
        "& .MuiPaper-root": {
            boxShadow: 'rgb(0 0 0 / 10%) 0 4px 8px 0',
            border: `1px solid ${palette.grey.iron}`,
            borderRadius: '8px',
            padding: '16px 20px',
            "& .MuiMenu-list": {
                color: palette.primary.main,
                "& .MuiMenuItem-root": {
                    fontSize: '15px',
                    ...lmsLinkHover
                }
            }
        }
    },
    landingPage: {
        heading0: {
            fontSize: 53,
            lineHeight: '53px',
            fontWeight: 'bold'
        },
        heading1: {
            fontSize: 43,
            lineHeight: '43px',
            fontWeight: 'bold'
        },
        heading2: {
            fontSize: 36,
            lineHeight: '43px',
            fontWeight: 'bold'
        },
        title: {
            fontSize: 34,
            lineHeight: '40px',
            fontWeight: 'bold'
        },
        title2: {
            fontSize: 30,
            lineHeight: '30px',
            fontWeight: 'bold'
        },
        title3: {
            fontSize: 28,
            lineHeight: '34px',
            fontWeight: 'bold'
        },
        title4: {
            fontSize: 26,
            lineHeight: '31px',
        },
        title5: {
            fontSize: 24,
            lineHeight: '24px',
            fontWeight: 'bold'
        },
        title6: {
            fontSize: 20,
            lineHeight: '24px',
        },
        body: {
            fontSize: 19,
            lineHeight: '31px',
        },
        body2: {
            fontSize: 18,
            lineHeight: '32px',
        },
        body3: {
            fontSize: 16,
            lineHeight: '29px',
            "@media(min-width: 768px)": {
                fontSize: 18,
                lineHeight: '32px',
            },
        },
        subtitle1: {
            fontSize: '14px',
            lineHeight: '14px',
        },
        subtitle2: {
            fontSize: '12px',
        },
        actionButton: {
            width: 240,
            minHeight: 20,
            lineHeight: '20px',
            borderRadius: 6,
            backgroundColor: palette.landingPage.primary,
            color: '#FFF',
            padding: '25px 16px',
            border: `2px solid ${palette.landingPage.primary}`,
            fontSize: 16,
            "&:hover": {
                textDecoration: 'none'
            }
        }
    }
};

export default typography;
