import "@styles/globals.css";
import React, {useCallback, useEffect, useState} from "react";
import Layout from "@layout/Layout";
import useAuth from "@util/hooks/useAuth/useAuth";
import dynamic from 'next/dynamic';
import {createTheme} from '@material-ui/core/styles';
import Head from "next/head";

const Spinner = dynamic(() => import('@ui/Spinner/Spinner'), {ssr: false});
const SSRSpinner = dynamic(() => import('@util/SSRSpinner/SSRSpinner'), {ssr: false});

//Material UI
import CssBaseline from "@material-ui/core/CssBaseline";
//import theme from "@helper/theme/theme";
import {ThemeProvider as MuiThemeProvider} from "@material-ui/styles";
import useCustomTheme from "@util/hooks/useCustomTheme/useCustomTheme";

//Redux
import {wrapper} from "@store/store";
import {useDispatch, useSelector} from "react-redux";
import {setWhichDomain} from "@store/ui/actions";
import useAffiliate from "@util/hooks/useAffiliate/useAffiliate";

/*export function reportWebVitals(metric) {
    console.log(metric)
}*/

function MyApp({Component, pageProps}) {

    const dispatch = useDispatch();

    const [muiTheme, setMuiTheme] = useState(null);

    const {favIcon} = useSelector(state => state.siteDetail);

    //!isolatedRoutes.includes(router.pathname) && useAuth();
    useAuth();


    React.useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    const SafeHydrate = useCallback(function ({children}) {
        return (
            <div suppressHydrationWarning>
                {typeof window === 'undefined' ? null : children}
            </div>
        )
    }, []);

    let theme = useCustomTheme();
    useAffiliate();

    useEffect(() => {
        if (theme) {
            setMuiTheme(() => {
                return createTheme(theme);
            })
        }
    }, [theme])

    useEffect(() => {
        dispatch(setWhichDomain())
    }, [typeof window])


    return (
        <>
            <Head>
                <link rel="icon" href={favIcon}/>
            </Head>
            {
                muiTheme &&
                <>

                    <MuiThemeProvider theme={muiTheme}>
                        <CssBaseline/>
                        <Spinner/>
                        <Layout>
                            <SafeHydrate>
                                <Component {...pageProps} />
                            </SafeHydrate>
                        </Layout>
                        {/*{
                    pageProps.domain === zeeshanShaikhDomain && (!isAuthenticated || !auth_user?.has_membership) ?
                        <SafeHydrate>
                            <Component {...pageProps} />
                        </SafeHydrate> :
                        <>
                            <Layout>
                                <SafeHydrate>
                                    <Component {...pageProps} />
                                </SafeHydrate>
                            </Layout>
                        </>
                }*/}
                        <SSRSpinner/>
                    </MuiThemeProvider>
                </>
            }
        </>
    );
}

export default wrapper.withRedux(MyApp);
