import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showData: {
        products: null
    }
}

const slice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setShowData (state, action) {
            state.showData.products = action.payload.products;
        },
        resetShowData (state) {
            state.showData.products = initialState.showData.products;
        },
        toggleMarkAsComplete (state, action) {
            let products = JSON.parse(JSON.stringify(state.showData.products))

            if (products && products.length) {
                let targetProductIndex = products.findIndex(product => {
                    return product.product_id === action.payload.productId
                });

                if (targetProductIndex !== -1) {
                    products[targetProductIndex].is_completed = action.payload.status;
                    state.showData.products = products;
                }
            }
        },
        resetUserWatchHistory (state, action) {
            let products = JSON.parse(JSON.stringify(state.showData.products))

            if (products && products.length) {
                let targetProductIndex = products.findIndex(product => {
                    return product.product_id === action.payload.productId
                });

                if (targetProductIndex !== -1) {
                    products.splice(targetProductIndex, 1);
                    state.showData.products = products;
                }
            }
        },
    },
});

export const historyActions = slice.actions;

export default slice.reducer;
