import {createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

const initialState = {
    authLoading: true,
    isAuthenticated: false,
    auth_user: null,
}

const slice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAuthenticated(state) {
            state.isAuthenticated = true;
            state.authLoading = false;
        },
        setUser(state, action) {
            state.auth_user = action.payload.auth_user
        },
        updateUser(state, action) {
            state.auth_user.first_name = action.payload.first_name;
            state.auth_user.last_name = action.payload.last_name;
            state.auth_user.email = action.payload.email;
            state.auth_user.iso_code = action.payload.iso_code;
            state.auth_user.isd_code = action.payload.isd_code;
            state.auth_user.mobile_number = action.payload.mobile_number;
        },
        setUnAuthenticated(state) {
            state.isAuthenticated = false;
            state.authLoading = false;
        },
        setForgotPasswordEmailSent(state) {
            state.forgotPasswordEmailSent = true;
        },
        unsetForgotPasswordEmailSent(state) {
            state.forgotPasswordEmailSent = false;
        },
    },
    extraReducers: {
        [HYDRATE] (state, action) {
            state.authLoading = action.payload.auth.authLoading;
            state.isAuthenticated = action.payload.auth.isAuthenticated;
            //state.auth_user = action.payload.auth.auth_user;
        }
    }
});

export const authActions = slice.actions;

export default slice.reducer;
