export const AUTH = {
    login: "/login",
    forgotPassword: "/forgot-password",
    resetPassword: "/forgot-password/[token]",
    socialProviderRedirect: "/auth/[provider]/redirectToSocialProvider"
};

export const WELCOME = {
    show: "/"
};

export const ORDER = {
    show: "/orders/[id]",
    create: "/orders/[id]/create",
    store: "/orders/[id]/store",
    //pay: "/orders/:id/pay",
};

export const USER = {
    edit: "/users/edit",
    library: "/users/library"
};

export const PRODUCT = {
    show: "/products/show",
    //view: "/products/:slug",
    view: "/products/[slug]",
};

export const CHECKOUT = {
    authCheckout: "/checkout/[access_token]",
    show: "/checkout",
    store: "/checkout/store",
};

export const CONTACT = {
    show: "/contact",
};

export const TERMS_PRIVACY_AND_HELP = {
    terms: "/terms",
    privacy: "/privacy",
    help: "/help",
    about: "/about",
    careers: "/careers",
    affiliates: "/affiliates",
    refund: "/refund",
    team: "/team",
};

export const AFFILIATE = {
    dashboard: "/affiliates/dashboard",
};

export const BLOG = {
    show: "/blogs",
};

export const PAYMENT = {
    response: "/payment-response",
};

export const LANDING = {
    home: "/landing-page",
};

export const WORKSHOP = {
    dbc: "/dbc-academy-workshop",
};

export const PR = {
    c3TelegramBonus: "c3-telegram-bonus",
};

export const TEAM = {
    show: "/team",
};

export const authRoutes = [
    AUTH.login,
    AUTH.forgotPassword,
    AUTH.resetPassword
];

export const protectedRoutes = [
    ORDER.show,
    USER.edit,
    USER.library,
    CHECKOUT.show,
    AFFILIATE.dashboard,
];

export const publicRoutes = [
    WELCOME.show,
    PRODUCT.view,
    TEAM.show
];

export const isolatedRoutes = [
    PAYMENT.response,
    LANDING.home
];

