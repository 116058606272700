import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import React, {Fragment, useState, useEffect} from 'react';

//Material UI
import Snackbar from '@material-ui/core/Snackbar';
import {Alert} from "@material-ui/lab";
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import {makeStyles, useTheme} from "@material-ui/core";
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import CheckIcon from '@material-ui/icons/Check';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';


//Redux
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "@store/ui/slice";

const useStyles = makeStyles(theme => ({
    container: {
        minHeight: '64px',
        maxHeight: '800px',
        padding: '8px 15px',
        boxShadow: '0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%)',
        borderRadius: '5px',
        "& .MuiAlert-root ": {
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: '14px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            backgroundColor: 'unset',
            padding: 0,
            "& .MuiSvgIcon-root": {
                color: '#FFF',
                fontSize: '2.5rem'
            },
            "& .MuiAlert-message": {
                color: '#FFF',
            },
            "& .MuiAlert-action": {
                "& .MuiSvgIcon-root": {
                    color: '#FFF',
                    fontSize: '1.2rem'
                },
            }
        },
        [theme.breakpoints.up('sm')]: {
            "&.MuiSnackbar-anchorOriginBottomRight": {
                width: '100%',
                maxWidth: '500px'
            }
        }
    },
}));


const SnackBar = () => {

    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [activeConfig, setActiveConfig] = useState(null);
    let {severity, message} = useSelector(state => state.ui.apiResponse.snackbar);

    const config = {
        error: {
            icon: <WarningOutlinedIcon/>,
            background: theme.palette.danger.text,
        },
        success: {
            icon: <CheckIcon/>,
            background: theme.palette.primary.main,
        },
        info: {
            icon: <ErrorOutlinedIcon/>,
            background: theme.palette.grey.persian_blue,
        },
    };

    useEffect(() => {
        if (severity) {
            setActiveConfig(config[severity]);
        } else {
            setActiveConfig(null);
        }
    }, [severity]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setActiveConfig(null);

        dispatch(uiActions.unsetSnackbar({
            severity: null,
            message: ''
        }));
    };

    return (
        <Fragment>
            {
                severity && activeConfig &&
                <Snackbar
                    className={classes.container}
                    open={true}
                    autoHideDuration={10000}
                    onClose={handleClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    style={{
                        backgroundColor: activeConfig.background
                    }}
                >
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        icon={activeConfig.icon}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            }
        </Fragment>
    );
};

export default SnackBar;
